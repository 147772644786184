import { Validation } from '../translations-types';

const translations: Validation = {
  validEmailIsRequired: 'Valid email address is required',
  youMustSelectASalon: 'You must select a branch',
  currentPasswordRequired: 'Current password is required',
  passwordConfirmationRequired: 'Password confirmation is required',
  passwordRequirements:
    'Password must contain at least one lowercase letter, one uppercase letter, one number and must consist of at least 8 characters',
  passwordsDoNotMatch: 'Passwords do not match!',
  legalCompanyNameIsRequired: 'Legal company name is required',
  companyNameIsAlreadyInUse: 'This company name is already in use',
  companyNameToUseIsRequired: 'Company name to use is required',
  organizationWebAddressIsRequired: 'Organisation web address is required',
  salonNameRequired: 'Branch name is required',
  roleNameRequired: 'Role name is required',
  displayNameRequired: 'Display name is required',
  propertyNameRequired: 'Property name is required',
  invoiceLanguageIsRequired: 'Invoice language is required',
  languageIsRequired: 'Language is required',
  tooManyRequests: 'Too many requests, please try again later.',
  phoneNumber: 'Phone number must be in the international format of f.e. +31612345678.',
  atLeastOneRole: 'At least one branch access role has to be selected for an employee.',
  oops: 'Oops!',
  anUnexpectedErrorHasOccured: 'An unexpected error has occurred.',
  error404: 'Error 404 - Unfortunately this page not found',
  groupAlreadyExists: 'A group with the given name already exists.',
  countryIsRequired: 'Country is required',
  addressIsRequired: 'Address line 1 is required',
  postalCodeIsRequired: 'Postal code required',
  cityIsRequired: 'City is required',
  isRequired: 'is required',
  slugIsAlreadyInUse: 'Web address is already in use',
  slugIsRequired: 'Web address is required',
  contactEmployeeIsRequired: 'Contact person is required',
  vatNumberIsRequired: 'VAT Number is required',
  someQuestionsNotAnswered: 'Some required questions have not been answered.',
};

export default translations;
