import { EmployeeDrawer } from '../translations-types';

const translations: EmployeeDrawer = {
  editEmployeeData: 'Wijzigen medewerker',
  createAnEmployee: 'Toevoegen medewerker',
  limitReached:
    'De limiet van {{employees}} medewerkers is bereikt. Om meer medewerkers te kunnen toevoegen  moet je je abonnement aanpassen.',
};

export default translations;
