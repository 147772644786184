import { Advice } from '../translations-types';

const translations: Advice = {
  addAdvice: 'Add Grow module',
  addAdviceCondition: 'Add Grow module condition',
  addAdviceGroup: 'Add Grow module group',
  adviceName: 'Grow module name',
  successfullyUpdated: 'Successfully updated advice',
  successfullyCreated: 'Successfully created advice',
  createAdvice: 'Create Grow module',
  viewAdvice: 'View Grow module',
  editAdvice: 'Edit Grow module',
  enterNewGroupName: 'Enter new group name',
  group: 'Group',
  adviceConditionName: 'Grow module condition name',
  adviceGroupName: 'Grow module group name',
  questions: 'Questions',
  areYouSureYouWantToDeleteAdviceGroup: 'Are you sure you want to delete this Grow module group?',
  areYouSureYouWantToDeleteAdviceCondition: 'Are you sure you want to delete this Grow module condition?',
  conditions: 'Conditions',
  advice: 'Grow module',
  addOption: 'Add option',
  podcastUrl: 'Podcast URL',
  videoUrl: 'Video URL',
  adviceOrUrl: 'Grow module content can be provided as text or as a URL.',
  watchOnVimeo: 'Watch on Vimeo',
  listedOnSpotify: 'Listen on Spotify',
  conditionsOrLogic: 'Grow module will be displayed if any of these conditions is met.',
  groupIsUsedTimes: 'This group is used {{count}} times.',
  conditionIsUsedTimes: 'This condition is used {{count}} times.',
  noAdvice: 'No Grow module to display.',
  conditionModalInfoText: 'Condition will be matched if all subconditions are met.',
  adviceX: 'Grow module {{number}}',
  audio: 'Audio',
  changeAudio: 'Change audio',
  removeAudio: 'Remove audio',
  chooseAudio: 'Choose audio',
};

export default translations;
