import { AdminLogin } from '../translations-types';

const translations: AdminLogin = {
  signIn: 'Sign in',
  logIn: 'Log in',
  dontHaveAccount: `Don't have an account?`,
  signUp: 'Sign up',
  forgotPassword: 'Forgot password?',
};

export default translations;
