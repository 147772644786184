import { GlobalImages } from '../translations-types';

const translations: GlobalImages = {
  Alert: 'Attentie',
  'Annual rings': 'Jaarringen',
  'Beautiful skin': 'Mooie huid',
  'Beauty treatment': 'Schoonheidsbehandeling',
  Conversation: 'Praten',
  Description: 'Omschrijving',
  Email: 'E-mail',
  Fingerprint: 'Vingerafdruk',
  Functional: 'Functioneel',
  'Magnifying glass': 'Vergrootglas',
  'Medical allert': 'Medisch alarm',
  Money: 'Geld',
  Otherwise: 'Anders',
  Personal: 'Persoonlijk',
  Question: 'Vraag',
  Questionmark: 'Vraagteken',
  Relaxation: 'Ontspanning',
  'Skin allergy': 'Huidallergie',
  'Skin care': 'Huidverzorging',
  Telephone: 'Telefoon',
  Triquetra: 'Triquetra',
  Vaccination: 'Vaccinatie',
  Whatsapp: 'Whatsapp',
  'Bloodflow high': 'Hoge bloeddruk',
  'Bloodflow low': 'Lage bloeddruk',
  'Bloodflow normal': 'Normale bloeddruk',
  'Skin group A': 'FP Huidtype A',
  'Skin group B': 'FP Huidtype B',
  'Skin group C': 'FP Huidtype p C',
  'Skin group D': 'FP Huidtype D',
  'Skintype combined': 'Huidtype combined',
  'Skintype dry': 'Huidtype dry',
  'Skintype normal': 'Huidtype normal',
  'Skintype oily': 'Huidtype oily',
  'Skintype sensitive': 'Huidtype sensitive',
  'Anti aging': 'Anti-veroudering',
  'Preventing aging': 'Voorkomen veroudering',
  'Barrier min': 'Barrière min',
  'Barrier neutral': 'Barrière neutral',
  'Barrier plus': 'Barrière plus',
  'Collagen deep skin min': 'Collageen diepe huid min',
  'Collagen deep skin neutral': 'Collageen diepe huid neutral',
  'Collagen deep skin plus': 'Collageen diepe huid plus',
  'Collagen dermis min': 'Collageen dermis min',
  'Collagen dermis neutral': 'Collageen dermis neutral',
  'Collagen dermis plus': 'Collageen dermis plus',
  'Connection min': 'Verbindingen min',
  'Connection neutral': 'Verbindingen neutral',
  'Connection plus': 'Verbindingen plus',
  'Thickness normal to thick': 'Dikte normaal tot dik',
  'Thickness thin': 'Dikte dun',
  'Thickness tick': 'Dikte dik',
  'Thickness very thin': 'Dikte zeer dun',
  'Tickness normal': 'Dikte normaal',
  'Deep pigmentation min': 'Diepe pigmentatie min',
  'Deep pigmentation neutral': 'Diepe pigmentatie neutral',
  'Deep pigmentation plus': 'Diepe pigmentatie plus',
  'Hydration min': 'Hydratatie min',
  'Hydration neutral': 'Hydratatie neutral',
  'Hydration plus': 'Hydratatie plus',
  'Inflammation min': 'Ontsteking min',
  'Inflammation neutral': 'Ontsteking neutral',
  'Inflammation plus': 'Ontsteking plus',
  'Oily skin min': 'Vettige huid min',
  'Oily skin neutral': 'Vettige huid neutral',
  'Oily skin plus': 'Vettige huid plus',
  'Ph min': 'Ph min',
  'Ph neutral': 'Ph neutral',
  'Ph plus': 'Ph plus',
  'Sensitive skin': 'Gevoelige huid',
  'Actinic keratosis': 'Actinische keratose',
  'Brown patches': 'Bruine vlekken',
  'Cold sore': 'Koortslip',
  'Connective tissue bump': 'Bindweefsel bult',
  'Couperose or spider neavi': 'Couperose of spider neavi',
  'Diffuse redness': 'Diffuse roodheid',
  'Easy bruising': 'Gemakkelijk blauwe plekken',
  Fibroma: 'Fibroom',
  Millia: 'Millia',
  'Spider naevi': 'Spider naevi',
  'Thick disorders stratum corneum': 'Dikke aandoeningen stratum opperhuid',
  Wounds: 'Wonden',
  'Abnormal moles': 'Abnormale moedervlekken',
  'Brown spot': 'Bruine vlek',
  Freckles: 'Sproeten',
  Hypopigmentation: 'Hypo-pigmentatie',
  'Pigmentary blemishes': 'Pigmentvlekken',
  'Pigmentation diffuse': 'Pigmentatie diffuus',
  'Skin color uneven': 'Ongelijke huidskleur',
  'Rosacea type 1': 'Rosacea type 1',
  'Rosacea type 2': 'Rosacea type 2',
  'Rosacea type 3': 'Rosacea type 3',
  Blackheads: 'Mee-eters',
  'Blind cystes': 'Blind cystes',
  'Inflamed blackheads': 'Ontstoken mee-eters',
  'Inflammation high ': 'Ontsteking hoog',
  'Inflammation small': 'Ontsteking klein',
  Nodulus: 'Nodulus',
  Papuls: 'Papulus',
  Pimpels: 'Puistjes',
  'Redness and blackheads': 'Roodheid en zwarte mee-eters',
  'Sebum white blackheads': 'Talg witte mee-eters',
  Whiteheads: 'Witte mee-eters',
  'Eczema dots': 'Eczeem puntjes',
  'Eczema face body': 'Eczeem gezicht lijf',
  'Icepick scar': 'IJspriem litteken',
  Keloid: 'Keloïde',
  'Scar stiches': 'Littekensteken',
  Scar: 'Litteken',
  'Scars acne': 'Littekens acne',
  'Scars little': 'Littekens klein',
  Scratches: 'Krassen',
  'Fine lines': 'Fijne lijntjes',
  'Frons wrinkles': 'Fronsrimpels',
  'Mimic wrinkles': 'Mimische rimpels',
  'Static wrinkles': 'Statische rimpels',
  'Wrinkles deep static': 'Rimpels diep statisch',
  'Double chin': 'Dubbele kin',
  'Volume less': 'Volumeverlies',
  'Volume loss cheek': 'Volumeverlies wang',
  'Volume loss cleavage': 'Volumeverlies decolleté',
  'Volume loss eyes': 'Volumeverlies ogen',
  'Volume loss forehead': 'Volumeverlies voorhoofd',
  'Volume loss jawline': 'Volumeverlies kaaklijn',
  'Volume loss neck': 'Volumeverlies nek',
  'Eye blue bag': 'Blauwe wal',
  'Eye fat bag': 'Vet wal',
  'Eye irritation': 'Oog irritatie',
  'Eye pigmentation': 'Oog pigmentatie',
  'Eye red bag': 'Rode wal',
  'Eye tissue bag': 'Oog huid wal',
  'Eye white dots': 'Oog witte plekjes',
  '00 General': 'Generiek',
  '01 Skin images': 'Huidbeelden',
  '02 Pigmentation': 'Pigmentatie',
  '04 Acne & inflammation': 'Acne & ontsteking',
  '05 Eczema': 'Eczeem',
  '06 Scars': 'Littekens',
  '07 Wrinkles': 'Rimpels',
  '08 Volume': 'Volume',
  '09 Eyes': 'Ogen',
  'Birthcontrol pil': 'Anticonceptie pil',
  'Copper IUD': 'Koperen spiraaltje',
  'Hormone IUD': 'Hormoon spiraaltje',
  Pregnant: 'Zwanger',
  Transition: 'Overgang',
  'Bad breath': 'Slechte adem',
  Depressed: 'Depressief',
  Hairloss: 'Haar verlies',
  Moodswings: 'Stemmingswisselingen',
  Rushed: 'Gehaast',
  Sad: 'Verdrietig',
  'Stiff joints': 'Stijve gewrichten',
  'Tongue rash': 'Tong aanslag',
  Breathing: 'Admenhaling',
  'Hot cold': 'Heet koude',
  'Fillers Botox': 'Fillers Botox',
  'Fillers permanent': 'Fillers permanent',
  'Medical history': 'Medische geschiedenis',
  Medication: 'Medicatie',
  'Plastic surgery': 'Plastische chirurgie',
  Alcohol: 'Alcohol',
  Coffee: 'Koffie',
  Energydrinks: 'Energiedranken',
  Soda: 'Soda',
  Water: 'Water',
  'Ayur veda': 'Ayur veda',
  Chinese: 'Chinese',
  Herbs: 'Kruiden',
  Homeopathy: 'Homeopathy',
  'Natural doctor': 'Natuurlijk arts',
  Supplements: 'Supplementen',
  Vitamines: 'Vitaminen',
  Bread: 'Brood',
  Dairy: 'Zuivel',
  'Fatty acids': 'Vetzuren',
  Fish: 'Vis',
  'Gluten intolerance': 'Gluten intolerantie',
  'Intermittent fasting': 'Intermittent fasting',
  Keto: 'Keto',
  'Low carb': 'Koolhydraatarm',
  Meat: 'Vlees',
  'No fish': 'Geen vis',
  'No fruit': 'Geen fruit',
  'No grain': 'Geen graan',
  'No meat': 'Geen vleest',
  'No vegetables': 'Geen groeten',
  Paleo: 'Paleo',
  Vegan: 'Veganistisch',
  Vegetarian: 'Vegetarisch',
  Cravings: 'Verlangen',
  Drugs: 'Drugs',
  Smoking: 'Roken',
  Sugar: 'Suiker',
  'Self love': 'Zelfliefde',
  Sleep: 'Slaap',
  Stress: 'Spanning',
  Zen: 'Zen',
  Airco: 'Airco',
  'Inside outside': 'Binnen buiten',
  Inside: 'Binnen',
  Outside: 'Buiten',
  Activity: 'Activiteit',
  Children: 'Kinderen',
  'Multiple births': 'Tweelingen',
  Relation: 'Relatie',
  'Defacation big dry': 'Ontlasting groot droog',
  'Defacation dry': 'Ontlasting droog',
  'Defacation little thin': 'Ontlasting klein dun',
  'Defacation normal': 'Ontlasting normaal',
  'Defacation small': 'Ontlasting klein',
  'Defacation thick small': 'Ontlasting dik klein',
  'Defacation thin': 'Ontlasting dun',
  Gut: 'Darmen',
  Exclamation: 'Uitroepteken',
  Stars: 'Sterren',
  '100% Natural': '100% Naturlijk',
  '100% Vegan': '100% Veganistisch',
  'Animal testing free': 'Dierproefvrij',
  Hormones: 'Hormonen',
  'No perfume': 'Geen parfum',
  'Paraben free': 'Parabenen vrij',
  Perfectly: 'Perfect',
  Perfume: 'Parfum',
  Hair: 'Haar',
  Party: 'Feest',
  'Skincare past': 'Huidverzorging verleden',
  'Sun future': 'Zon toekomst',
  'Sun past': 'Zon verleden',
  'This moment': 'Dit moment',
  'Eye brow': 'Wenkbrauw',
  'Eye lashes': 'Wimpers',
  Waxing: 'Waxen',
  Face: 'Gezicht',
  Age: 'Leeftijd',
  Ethnic: 'Etnisch',
  Feeling: 'Gevoel',
  Gender: 'Geslacht',
  'Grade past': 'Cijfer verleden',
  Mature: 'Volwassen',
  Old: 'Oud',
  Time: 'Tijd',
  Young: 'Jong',
  '24-hour creme': '24-hour creme',
  'Acne littekens': 'Acne littekens',
  Advice: 'Advice',
  'Advies opties': 'Advies opties',
  'All nutrients': 'All nutrients',
  'Allergieen extra': 'Allergieen extra',
  Ampules: 'Ampules',
  'Apparatuur advies': 'Apparatuur advies',
  Audio: 'Audio',
  Autoimmune: 'Autoimmune',
  'Bad healing': 'Bad healing',
  'Balans extra': 'Balans extra',
  'Belly fat': 'Belly fat',
  Belly: 'Belly',
  Beweging: 'Beweging',
  Bioritme: 'Bioritme',
  'Bleeding gums': 'Bleeding gums',
  Bloedverdunners: 'Bloedverdunners',
  'Blood thinners': 'Blood thinners',
  'Body massage': 'Body massage',
  Body: 'Body',
  Bodycontouring: 'Bodycontouring',
  'Bread sort': 'Bread sort',
  'Bread toppings': 'Bread toppings',
  Breastfeeding: 'Breastfeeding',
  'Burn out': 'Burn out',
  'Buy habits': 'Buy habits',
  'Canker sores': 'Canker sores',
  Care: 'Care',
  'Carnivor diet': 'Carnivor diet',
  Cellular: 'Cellular',
  Cellulite: 'Cellulite',
  'Chakra system': 'Chakra system',
  'Chewing gum': 'Chewing gum',
  'Child wish': 'Child wish',
  Clarity: 'Clarity',
  Cleaning: 'Cleaning',
  Cleanser: 'Cleanser',
  Clearing: 'Clearing',
  'Coarse pored nose': 'Coarse pored nose',
  'Coarse pored': 'Coarse pored',
  'Collagen and elistine': 'Collagen and elistine',
  Communication: 'Communication',
  Compass: 'Compass',
  'Complication pregnacy': 'Complication pregnacy',
  Condition: 'Condition',
  Consideration: 'Consideration',
  'Cosmetic surgery': 'Cosmetic surgery',
  Cramp: 'Cramp',
  Cream: 'Cream',
  DNA: 'DNA',
  'Day cream': 'Day cream',
  'Day make-up': 'Day make-up',
  Dehydrated: 'Dehydrated',
  Depression: 'Depression',
  'Dertig plus': 'Dertig plus',
  'Diluting cream': 'Diluting cream',
  Dizzy: 'Dizzy',
  Doorverwezen: 'Doorverwezen',
  'Drinken extra': 'Drinken extra',
  'Dust allegy': 'Dust allegy',
  Echo: 'Echo',
  'Egg allegy': 'Egg allegy',
  Elasticity: 'Elasticity',
  'Energy dips': 'Energy dips',
  'Energy future': 'Energy future',
  'Energy gives': 'Energy gives',
  'Energy past': 'Energy past',
  Energy: 'Energy',
  Epilation: 'Epilation',
  'Ervaring met specialist': 'Ervaring met specialist',
  Ervaring: 'Ervaring',
  'Eten extra': 'Eten extra',
  'Excessive hair': 'Excessive hair',
  'Expert says': 'Expert says',
  'Expert sees': 'Expert sees',
  Expert: 'Expert',
  Extra: 'Extra',
  'Eye cream': 'Eye cream',
  FP1: 'FP1',
  FP2: 'FP2',
  FP3: 'FP3',
  FP4: 'FP4',
  FP5: 'FP5',
  FP6: 'FP6',
  'Face and neck massage': 'Face and neck massage',
  'Feeling after treatment': 'Feeling after treatment',
  'First sun': 'First sun',
  'Flat buttocks': 'Flat buttocks',
  Flatulence: 'Flatulence',
  'Food moments': 'Food moments',
  'Foot treatment': 'Foot treatment',
  Foundation: 'Foundation',
  Fruits: 'Fruits',
  'Gemiddeld gevoelig': 'Gemiddeld gevoelig',
  'Gemiddeld intolerant': 'Gemiddeld intolerant',
  Goal: 'Goal',
  'Gut health': 'Gut health',
  'Hand treatment': 'Hand treatment',
  'Happy goal': 'Happy goal',
  Happy: 'Happy',
  Headache: 'Headache',
  'Home device': 'Home device',
  'Hormonen extra': 'Hormonen extra',
  'House dust allergy': 'House dust allergy',
  Huidplan: 'Huidplan',
  Hydration: 'Hydration',
  "I don't know": "I don't know",
  'Immune problem': 'Immune problem',
  Implants: 'Implants',
  Important: 'Important',
  Inflammation: 'Inflammation',
  'Inject face': 'Inject face',
  'Inside and outside buttocks and legs': 'Inside and outside buttocks and legs',
  'Inside legs': 'Inside legs',
  'Insulin resistent': 'Insulin resistent',
  Internet: 'Internet',
  'Interval periode': 'Interval periode',
  Interval: 'Interval',
  'Irregular heart beat': 'Irregular heart beat',
  Irregular: 'Irregular',
  Itch: 'Itch',
  Juices: 'Juices',
  'Keratosis pilaris': 'Keratosis pilaris',
  Klachten: 'Klachten',
  Koolhydraten: 'Koolhydraten',
  'Lactose intolerance': 'Lactose intolerance',
  "Let's go": "Let's go",
  'Lifting eyes': 'Lifting eyes',
  Light: 'Light',
  'Loose skin': 'Loose skin',
  Lungs: 'Lungs',
  'Make-up advice': 'Make-up advice',
  'Make-up': 'Make-up',
  Mask: 'Mask',
  Measure: 'Measure',
  'Medische zalf': 'Medische zalf',
  Microplastics: 'Microplastics',
  'Milk and or sugar': 'Milk and or sugar',
  Milk: 'Milk',
  'Mond tot mond': 'Mond tot mond',
  'Moon cycle': 'Moon cycle',
  Movement: 'Movement',
  'Muscle loss': 'Muscle loss',
  'My wishes': 'My wishes',
  'Nail treatment': 'Nail treatment',
  'Nature gives': 'Nature gives',
  'Neck cream': 'Neck cream',
  'Neck problems': 'Neck problems',
  Needs: 'Needs',
  'Niet gevoelig': 'Niet gevoelig',
  'Niet intolerant': 'Niet intolerant',
  'Night cream': 'Night cream',
  'Not all nutrients': 'Not all nutrients',
  'Not that treatment': 'Not that treatment',
  'Not varied': 'Not varied',
  'Nuts ans peanut allergy': 'Nuts ans peanut allergy',
  'Omega 3': 'Omega 3',
  Ontgifting: 'Ontgifting',
  'Orthomolecular doctor': 'Orthomolecular doctor',
  'Out of transition': 'Out of transition',
  PCOS: 'PCOS',
  Painting: 'Painting',
  'Peeling advies': 'Peeling advies',
  Period: 'Period',
  Photo: 'Photo',
  Pigmentatie: 'Pigmentatie',
  Pleased: 'Pleased',
  Polite: 'Polite',
  'Pollen allegy': 'Pollen allegy',
  'Poor moisture fat barrier': 'Poor moisture fat barrier',
  'Post trauma': 'Post trauma',
  Preference: 'Preference',
  'Price skincare': 'Price skincare',
  'Price treatment': 'Price treatment',
  Proteins: 'Proteins',
  Psoriasis: 'Psoriasis',
  Pubis: 'Pubis',
  'RAW food': 'RAW food',
  'Re-sence': 'Re-sence',
  Reclame: 'Reclame',
  Recognition: 'Recognition',
  Regular: 'Regular',
  Remark: 'Remark',
  'Restless legs': 'Restless legs',
  'Review delen': 'Review delen',
  Review: 'Review',
  Roots: 'Roots',
  'Running nose': 'Running nose',
  SPF: 'SPF',
  'Sad 2': 'Sad 2',
  Scrub: 'Scrub',
  Serum: 'Serum',
  'Shellfish and crustaceans': 'Shellfish and crustaceans',
  'Single hair': 'Single hair',
  'Skin adhesion': 'Skin adhesion',
  'Skin care at home': 'Skin care at home',
  'Skin care now': 'Skin care now',
  'Skin care preference': 'Skin care preference',
  'Skin future': 'Skin future',
  'Skin layers': 'Skin layers',
  'Skin number': 'Skin number',
  'Skin past': 'Skin past',
  'Skin plan advice': 'Skin plan advice',
  'Skin plan': 'Skin plan',
  'Skin problem': 'Skin problem',
  'Skin supplements': 'Skin supplements',
  'Skin surplus': 'Skin surplus',
  'Skin tan': 'Skin tan',
  'Slack arms': 'Slack arms',
  'Slack buttocks': 'Slack buttocks',
  'Sleep problems': 'Sleep problems',
  Sneeze: 'Sneeze',
  'Social media': 'Social media',
  Spirituality: 'Spirituality',
  Sport: 'Sport',
  'Stiff joints 2': 'Stiff joints 2',
  'Stomach ache': 'Stomach ache',
  'Stretch marks': 'Stretch marks',
  'Sugar balance': 'Sugar balance',
  'Sun habits': 'Sun habits',
  Surgery: 'Surgery',
  'Teary eyes': 'Teary eyes',
  Text: 'Text',
  'Thighs and abdomen cellulite': 'Thighs and abdomen cellulite',
  'Thighs and abdomen': 'Thighs and abdomen',
  'Thin breaking nails': 'Thin breaking nails',
  'Time alone': 'Time alone',
  'Time scale': 'Time scale',
  Tinnitus: 'Tinnitus',
  'Toelichting ervaring': 'Toelichting ervaring',
  Toner: 'Toner',
  'Tongue rash 2': 'Tongue rash 2',
  'Treat cure': 'Treat cure',
  Treatment: 'Treatment',
  Tree: 'Tree',
  Tutoyeren: 'Tutoyeren',
  'Twintig plus': 'Twintig plus',
  'U choose': 'U choose',
  'Unknown 2': 'Unknown 2',
  'Unknown cream': 'Unknown cream',
  'Unknown inflammation': 'Unknown inflammation',
  Vacation: 'Vacation',
  'Varicose veins': 'Varicose veins',
  Varied: 'Varied',
  'Veertig plus': 'Veertig plus',
  Vegetables: 'Vegetables',
  Veins: 'Veins',
  Video: 'Video',
  'Vijftig plus': 'Vijftig plus',
  Vital: 'Vital',
  'Vitaliteit cijfer': 'Vitaliteit cijfer',
  'Vitaliteit extra': 'Vitaliteit extra',
  'Vitality goal': 'Vitality goal',
  'Vitality tree': 'Vitality tree',
  Vitiligo: 'Vitiligo',
  Weerstand: 'Weerstand',
  'Welke skin care thuis': 'Welke skin care thuis',
  'What is it': 'What is it',
  'Whey protein': 'Whey protein',
  'White heads': 'White heads',
  'Wimpy head': 'Wimpy head',
  'Wish to come back': 'Wish to come back',
  Wish: 'Wish',
  World: 'World',
  'Wrinkles and volumeloss': 'Wrinkles and volumeloss',
  Yes: 'Yes',
  'Zeer gevoelig': 'Zeer gevoelig',
  'Zeer intolerant': 'Zeer intolerant',
  'Zestig plus': 'Zestig plus',
  'Zeventig plus': 'Zeventig plus',
  'Zonbescherming++': 'Zonbescherming++',
  'Zonbescherming+-': 'Zonbescherming+-',
  'Zonbescherming+': 'Zonbescherming+',
  'Zonbescherming--': 'Zonbescherming--',
  'Zonbescherming-': 'Zonbescherming-',
  'Zorg extra': 'Zorg extra',
  Zwangerschapsmasker: 'Zwangerschapsmasker',
  '10 Bodycontouring': '10 Bodycontouring',
  '11 Hair': '11 Hair',
  'Flat Buttocks': 'Flat Buttocks',
  'Inject Face': 'Inject Face',
};

export default translations;
