import { GlobalImages } from '../translations-types';

const translations: GlobalImages = {
  Alert: 'Alert',
  'Annual rings': 'Annual rings',
  'Beautiful skin': 'Beautiful skin',
  'Beauty treatment': 'Beauty treatment',
  Conversation: 'Conversation',
  Description: 'Description',
  Email: 'Email',
  Fingerprint: 'Blueprint',
  Functional: 'Functional',
  'Magnifying glass': 'Magnifying glass',
  'Medical allert': 'Medical alert',
  Money: 'Money',
  Otherwise: 'Otherwise',
  Personal: 'Personal',
  Question: 'Question',
  Questionmark: 'Questionmark',
  Relaxation: 'Relaxation',
  'Skin allergy': 'Skin allergy',
  'Skin care': 'Skin care',
  Telephone: 'Telephone',
  Triquetra: 'Triquetra',
  Vaccination: 'Vaccination',
  Whatsapp: 'Whatsapp',
  'Bloodflow high': 'Bloodflow high',
  'Bloodflow low': 'Bloodflow low',
  'Bloodflow normal': 'Bloodflow normal',
  'Skin group A': 'FP Skintype A',
  'Skin group B': 'FP Skintype B',
  'Skin group C': 'FP Skintype C',
  'Skin group D': 'FP Skintype D',
  'Skintype combined': 'Skintype combined',
  'Skintype dry': 'Skintype dry',
  'Skintype normal': 'Skintype normal',
  'Skintype oily': 'Skintype oily',
  'Skintype sensitive': 'Skintype sensitive',
  'Anti aging': 'Anti aging',
  'Preventing aging': 'Preventing aging',
  'Barrier min': 'Barrier min',
  'Barrier neutral': 'Barrier neutral',
  'Barrier plus': 'Barrier plus',
  'Collagen deep skin min': 'Collagen deep skin min',
  'Collagen deep skin neutral': 'Collagen deep skin neutral',
  'Collagen deep skin plus': 'Collagen deep skin plus',
  'Collagen dermis min': 'Collagen dermis min',
  'Collagen dermis neutral': 'Collagen dermis neutral',
  'Collagen dermis plus': 'Collagen dermis plus',
  'Connection min': 'Connection min',
  'Connection neutral': 'Connection neutral',
  'Connection plus': 'Connection plus',
  'Thickness normal to thick': 'Thickness normal to thick',
  'Thickness thin': 'Thickness thin',
  'Thickness tick': 'Thickness tick',
  'Thickness very thin': 'Thickness very thin',
  'Tickness normal': 'Thickness normal',
  'Deep pigmentation min': 'Deep pigmentation min',
  'Deep pigmentation neutral': 'Deep pigmentation neutral',
  'Deep pigmentation plus': 'Deep pigmentation plus',
  'Hydration min': 'Hydration min',
  'Hydration neutral': 'Hydration neutral',
  'Hydration plus': 'Hydration plus',
  'Inflammation min': 'Inflammation min',
  'Inflammation neutral': 'Inflammation neutral',
  'Inflammation plus': 'Inflammation plus',
  'Oily skin min': 'Oily skin min',
  'Oily skin neutral': 'Oily skin neutral',
  'Oily skin plus': 'Oily skin plus',
  'Ph min': 'Ph min',
  'Ph neutral': 'Ph neutral',
  'Ph plus': 'Ph plus',
  'Sensitive skin': 'Sensitive skin',
  'Actinic keratosis': 'Actinic keratosis',
  'Brown patches': 'Brown patches',
  'Cold sore': 'Cold sore',
  'Connective tissue bump': 'Connective tissue bump',
  'Couperose or spider neavi': 'Couperose or spider neavi',
  'Diffuse redness': 'Diffuse redness',
  'Easy bruising': 'Easy bruising',
  Fibroma: 'Fibroma',
  Millia: 'Millia',
  'Spider naevi': 'Spider naevi',
  'Thick disorders stratum corneum': 'Thick disorders stratum epidermis',
  Wounds: 'Wounds',
  'Abnormal moles': 'Abnormal moles',
  'Brown spot': 'Brown spot',
  Freckles: 'Freckles',
  Hypopigmentation: 'Hypopigmentation',
  'Pigmentary blemishes': 'Pigmentary blemishes',
  'Pigmentation diffuse': 'Pigmentation diffuse',
  'Skin color uneven': 'Skin color uneven',
  'Rosacea type 1': 'Rosacea type 1',
  'Rosacea type 2': 'Rosacea type 2',
  'Rosacea type 3': 'Rosacea type 3',
  Blackheads: 'Blackheads',
  'Blind cystes': 'Blind cystes',
  'Inflamed blackheads': 'Inflamed blackheads',
  'Inflammation high ': 'Inflammation high ',
  'Inflammation small': 'Inflammation small',
  Nodulus: 'Nodulus',
  Papuls: 'Papels',
  Pimpels: 'Pimpels',
  'Redness and blackheads': 'Redness and blackheads',
  'Sebum white blackheads': 'Sebum white blackheads',
  Whiteheads: 'Whiteheads',
  'Eczema dots': 'Eczema dots',
  'Eczema face body': 'Eczema face body',
  'Icepick scar': 'Icepick scar',
  Keloid: 'Keloid',
  'Scar stiches': 'Scar stitches',
  Scar: 'Scar',
  'Scars acne': 'Scars acne',
  'Scars little': 'Scars little',
  Scratches: 'Scratches',
  'Fine lines': 'Fine lines',
  'Frons wrinkles': 'Frons wrinkles',
  'Mimic wrinkles': 'Mimic wrinkles',
  'Static wrinkles': 'Static wrinkles',
  'Wrinkles deep static': 'Wrinkles deep static',
  'Double chin': 'Double chin',
  'Volume less': 'Volume less',
  'Volume loss cheek': 'Volume loss cheek',
  'Volume loss cleavage': 'Volume loss cleavage',
  'Volume loss eyes': 'Volume loss eyes',
  'Volume loss forehead': 'Volume loss forehead',
  'Volume loss jawline': 'Volume loss jawline',
  'Volume loss neck': 'Volume loss neck',
  'Eye blue bag': 'Eye blue bag',
  'Eye fat bag': 'Eye fat bag',
  'Eye irritation': 'Eye irritation',
  'Eye pigmentation': 'Eye pigmentation',
  'Eye red bag': 'Eye red bag',
  'Eye tissue bag': 'Eye tissue bag',
  'Eye white dots': 'Eye white dots',
  '00 General': 'General',
  '01 Skin images': 'Skin images',
  '02 Pigmentation': 'Pigmentation',
  '04 Acne & inflammation': 'Acne & inflammation',
  '05 Eczema': 'Eczema',
  '06 Scars': 'Scars',
  '07 Wrinkles': 'Wrinkles',
  '08 Volume': 'Volume',
  '09 Eyes': 'Eyes',
  'Birthcontrol pil': 'Birth control pil',
  'Copper IUD': 'Copper IUD',
  'Hormone IUD': 'Hormone IUD',
  Pregnant: 'Pregnant',
  Transition: 'Transition',
  'Bad breath': 'Bad breath',
  Depressed: 'Depressed',
  Hairloss: 'Hairloss',
  Moodswings: 'Mood swings',
  Rushed: 'Rushed',
  Sad: 'Sad',
  'Stiff joints': 'Stiff joints',
  'Tongue rash': 'Tongue rash',
  Breathing: 'Breathing',
  'Hot cold': 'Hot cold',
  'Fillers Botox': 'Fillers Botox',
  'Fillers permanent': 'Fillers permanent',
  'Medical history': 'Medical history',
  Medication: 'Medication',
  'Plastic surgery': 'Plastic surgery',
  Alcohol: 'Alcohol',
  Coffee: 'Coffee',
  Energydrinks: 'Energy drinks',
  Soda: 'Soda',
  Water: 'Water',
  'Ayur veda': 'Ayurveda',
  Chinese: 'Chinese',
  Herbs: 'Herbs',
  Homeopathy: 'Homeopathy',
  'Natural doctor': 'Natural doctor',
  Supplements: 'Supplements',
  Vitamines: 'Vitamines',
  Bread: 'Bread',
  Dairy: 'Dairy',
  'Fatty acids': 'Fatty acids',
  Fish: 'Fish',
  'Gluten intolerance': 'Gluten intolerance',
  'Intermittent fasting': 'Intermittent fasting',
  Keto: 'Keto',
  'Low carb': 'Low carb',
  Meat: 'Meat',
  'No fish': 'No fish',
  'No fruit': 'No fruit',
  'No grain': 'No grain',
  'No meat': 'No meat',
  'No vegetables': 'No vegetables',
  Paleo: 'Paleo',
  Vegan: 'Vegan',
  Vegetarian: 'Vegetarian',
  Cravings: 'Cravings',
  Drugs: 'Drugs',
  Smoking: 'Smoking',
  Sugar: 'Sugar',
  'Self love': 'Self love',
  Sleep: 'Sleep',
  Stress: 'Stress',
  Zen: 'Zen',
  Airco: 'Airco',
  'Inside outside': 'Inside outside',
  Inside: 'Inside',
  Outside: 'Outside',
  Activity: 'Activity',
  Children: 'Children',
  'Multiple births': 'Multiple births',
  Relation: 'Relation',
  'Defacation big dry': 'Defecation big dry',
  'Defacation dry': 'Defecation dry',
  'Defacation little thin': 'Defecation little thin',
  'Defacation normal': 'Defecation normal',
  'Defacation small': 'Defecation small',
  'Defacation thick small': 'Defecation thick small',
  'Defacation thin': 'Defecation thin',
  Gut: 'Gut',
  Exclamation: 'Exclamation',
  Stars: 'Stars',
  '100% Natural': '100% Natural',
  '100% Vegan': '100% Vegan',
  'Animal testing free': 'Animal testing free',
  Hormones: 'Hormones',
  'No perfume': 'No perfume',
  'Paraben free': 'Paraben free',
  Perfectly: 'Perfectly',
  Perfume: 'Perfume',
  Hair: 'Hair',
  Party: 'Party',
  'Skincare past': 'Skincare past',
  'Sun future': 'Sun future',
  'Sun past': 'Sun past',
  'This moment': 'This moment',
  'Eye brow': 'Eye brow',
  'Eye lashes': 'Eye lashes',
  Waxing: 'Waxing',
  Face: 'Face',
  Age: 'Age',
  Ethnic: 'Ethnic',
  Feeling: 'Feeling',
  Gender: 'Gender',
  'Grade past': 'Grade past',
  Mature: 'Mature',
  Old: 'Old',
  Time: 'Time',
  Young: 'Young',
  '24-hour creme': '24-hour creme',
  'Acne littekens': 'Acne littekens',
  Advice: 'Advice',
  'Advies opties': 'Advies opties',
  'All nutrients': 'All nutrients',
  'Allergieen extra': 'Allergieen extra',
  Ampules: 'Ampules',
  'Apparatuur advies': 'Apparatuur advies',
  Audio: 'Audio',
  Autoimmune: 'Autoimmune',
  'Bad healing': 'Bad healing',
  'Balans extra': 'Balans extra',
  'Belly fat': 'Belly fat',
  Belly: 'Belly',
  Beweging: 'Beweging',
  Bioritme: 'Bioritme',
  'Bleeding gums': 'Bleeding gums',
  Bloedverdunners: 'Bloedverdunners',
  'Blood thinners': 'Blood thinners',
  'Body massage': 'Body massage',
  Body: 'Body',
  Bodycontouring: 'Bodycontouring',
  'Bread sort': 'Bread sort',
  'Bread toppings': 'Bread toppings',
  Breastfeeding: 'Breastfeeding',
  'Burn out': 'Burn out',
  'Buy habits': 'Buy habits',
  'Canker sores': 'Canker sores',
  Care: 'Care',
  'Carnivor diet': 'Carnivor diet',
  Cellular: 'Cellular',
  Cellulite: 'Cellulite',
  'Chakra system': 'Chakra system',
  'Chewing gum': 'Chewing gum',
  'Child wish': 'Child wish',
  Clarity: 'Clarity',
  Cleaning: 'Cleaning',
  Cleanser: 'Cleanser',
  Clearing: 'Clearing',
  'Coarse pored nose': 'Coarse pored nose',
  'Coarse pored': 'Coarse pored',
  'Collagen and elistine': 'Collagen and elistine',
  Communication: 'Communication',
  Compass: 'Compass',
  'Complication pregnacy': 'Complication pregnacy',
  Condition: 'Condition',
  Consideration: 'Consideration',
  'Cosmetic surgery': 'Cosmetic surgery',
  Cramp: 'Cramp',
  Cream: 'Cream',
  DNA: 'DNA',
  'Day cream': 'Day cream',
  'Day make-up': 'Day make-up',
  Dehydrated: 'Dehydrated',
  Depression: 'Depression',
  'Dertig plus': 'Dertig plus',
  'Diluting cream': 'Diluting cream',
  Dizzy: 'Dizzy',
  Doorverwezen: 'Doorverwezen',
  'Drinken extra': 'Drinken extra',
  'Dust allegy': 'Dust allegy',
  Echo: 'Echo',
  'Egg allegy': 'Egg allegy',
  Elasticity: 'Elasticity',
  'Energy dips': 'Energy dips',
  'Energy future': 'Energy future',
  'Energy gives': 'Energy gives',
  'Energy past': 'Energy past',
  Energy: 'Energy',
  Epilation: 'Epilation',
  'Ervaring met specialist': 'Ervaring met specialist',
  Ervaring: 'Ervaring',
  'Eten extra': 'Eten extra',
  'Excessive hair': 'Excessive hair',
  'Expert says': 'Expert says',
  'Expert sees': 'Expert sees',
  Expert: 'Expert',
  Extra: 'Extra',
  'Eye cream': 'Eye cream',
  FP1: 'FP1',
  FP2: 'FP2',
  FP3: 'FP3',
  FP4: 'FP4',
  FP5: 'FP5',
  FP6: 'FP6',
  'Face and neck massage': 'Face and neck massage',
  'Feeling after treatment': 'Feeling after treatment',
  'First sun': 'First sun',
  'Flat buttocks': 'Flat buttocks',
  Flatulence: 'Flatulence',
  'Food moments': 'Food moments',
  'Foot treatment': 'Foot treatment',
  Foundation: 'Foundation',
  Fruits: 'Fruits',
  'Gemiddeld gevoelig': 'Gemiddeld gevoelig',
  'Gemiddeld intolerant': 'Gemiddeld intolerant',
  Goal: 'Goal',
  'Gut health': 'Gut health',
  'Hand treatment': 'Hand treatment',
  'Happy goal': 'Happy goal',
  Happy: 'Happy',
  Headache: 'Headache',
  'Home device': 'Home device',
  'Hormonen extra': 'Hormonen extra',
  'House dust allergy': 'House dust allergy',
  Huidplan: 'Huidplan',
  Hydration: 'Hydration',
  "I don't know": "I don't know",
  'Immune problem': 'Immune problem',
  Implants: 'Implants',
  Important: 'Important',
  Inflammation: 'Inflammation',
  'Inject face': 'Inject face',
  'Inside and outside buttocks and legs': 'Inside and outside buttocks and legs',
  'Inside legs': 'Inside legs',
  'Insulin resistent': 'Insulin resistent',
  Internet: 'Internet',
  'Interval periode': 'Interval periode',
  Interval: 'Interval',
  'Irregular heart beat': 'Irregular heart beat',
  Irregular: 'Irregular',
  Itch: 'Itch',
  Juices: 'Juices',
  'Keratosis pilaris': 'Keratosis pilaris',
  Klachten: 'Klachten',
  Koolhydraten: 'Koolhydraten',
  'Lactose intolerance': 'Lactose intolerance',
  "Let's go": "Let's go",
  'Lifting eyes': 'Lifting eyes',
  Light: 'Light',
  'Loose skin': 'Loose skin',
  Lungs: 'Lungs',
  'Make-up advice': 'Make-up advice',
  'Make-up': 'Make-up',
  Mask: 'Mask',
  Measure: 'Measure',
  'Medische zalf': 'Medische zalf',
  Microplastics: 'Microplastics',
  'Milk and or sugar': 'Milk and or sugar',
  Milk: 'Milk',
  'Mond tot mond': 'Mond tot mond',
  'Moon cycle': 'Moon cycle',
  Movement: 'Movement',
  'Muscle loss': 'Muscle loss',
  'My wishes': 'My wishes',
  'Nail treatment': 'Nail treatment',
  'Nature gives': 'Nature gives',
  'Neck cream': 'Neck cream',
  'Neck problems': 'Neck problems',
  Needs: 'Needs',
  'Niet gevoelig': 'Niet gevoelig',
  'Niet intolerant': 'Niet intolerant',
  'Night cream': 'Night cream',
  'Not all nutrients': 'Not all nutrients',
  'Not that treatment': 'Not that treatment',
  'Not varied': 'Not varied',
  'Nuts ans peanut allergy': 'Nuts ans peanut allergy',
  'Omega 3': 'Omega 3',
  Ontgifting: 'Ontgifting',
  'Orthomolecular doctor': 'Orthomolecular doctor',
  'Out of transition': 'Out of transition',
  PCOS: 'PCOS',
  Painting: 'Painting',
  'Peeling advies': 'Peeling advies',
  Period: 'Period',
  Photo: 'Photo',
  Pigmentatie: 'Pigmentatie',
  Pleased: 'Pleased',
  Polite: 'Polite',
  'Pollen allegy': 'Pollen allegy',
  'Poor moisture fat barrier': 'Poor moisture fat barrier',
  'Post trauma': 'Post trauma',
  Preference: 'Preference',
  'Price skincare': 'Price skincare',
  'Price treatment': 'Price treatment',
  Proteins: 'Proteins',
  Psoriasis: 'Psoriasis',
  Pubis: 'Pubis',
  'RAW food': 'RAW food',
  'Re-sence': 'Re-sence',
  Reclame: 'Reclame',
  Recognition: 'Recognition',
  Regular: 'Regular',
  Remark: 'Remark',
  'Restless legs': 'Restless legs',
  'Review delen': 'Review delen',
  Review: 'Review',
  Roots: 'Roots',
  'Running nose': 'Running nose',
  SPF: 'SPF',
  'Sad 2': 'Sad 2',
  Scrub: 'Scrub',
  Serum: 'Serum',
  'Shellfish and crustaceans': 'Shellfish and crustaceans',
  'Single hair': 'Single hair',
  'Skin adhesion': 'Skin adhesion',
  'Skin care at home': 'Skin care at home',
  'Skin care now': 'Skin care now',
  'Skin care preference': 'Skin care preference',
  'Skin future': 'Skin future',
  'Skin layers': 'Skin layers',
  'Skin number': 'Skin number',
  'Skin past': 'Skin past',
  'Skin plan advice': 'Skin plan advice',
  'Skin plan': 'Skin plan',
  'Skin problem': 'Skin problem',
  'Skin supplements': 'Skin supplements',
  'Skin surplus': 'Skin surplus',
  'Skin tan': 'Skin tan',
  'Slack arms': 'Slack arms',
  'Slack buttocks': 'Slack buttocks',
  'Sleep problems': 'Sleep problems',
  Sneeze: 'Sneeze',
  'Social media': 'Social media',
  Spirituality: 'Spirituality',
  Sport: 'Sport',
  'Stiff joints 2': 'Stiff joints 2',
  'Stomach ache': 'Stomach ache',
  'Stretch marks': 'Stretch marks',
  'Sugar balance': 'Sugar balance',
  'Sun habits': 'Sun habits',
  Surgery: 'Surgery',
  'Teary eyes': 'Teary eyes',
  Text: 'Text',
  'Thighs and abdomen cellulite': 'Thighs and abdomen cellulite',
  'Thighs and abdomen': 'Thighs and abdomen',
  'Thin breaking nails': 'Thin breaking nails',
  'Time alone': 'Time alone',
  'Time scale': 'Time scale',
  Tinnitus: 'Tinnitus',
  'Toelichting ervaring': 'Toelichting ervaring',
  Toner: 'Toner',
  'Tongue rash 2': 'Tongue rash 2',
  'Treat cure': 'Treat cure',
  Treatment: 'Treatment',
  Tree: 'Tree',
  Tutoyeren: 'Tutoyeren',
  'Twintig plus': 'Twintig plus',
  'U choose': 'U choose',
  'Unknown 2': 'Unknown 2',
  'Unknown cream': 'Unknown cream',
  'Unknown inflammation': 'Unknown inflammation',
  Vacation: 'Vacation',
  'Varicose veins': 'Varicose veins',
  Varied: 'Varied',
  'Veertig plus': 'Veertig plus',
  Vegetables: 'Vegetables',
  Veins: 'Veins',
  Video: 'Video',
  'Vijftig plus': 'Vijftig plus',
  Vital: 'Vital',
  'Vitaliteit cijfer': 'Vitaliteit cijfer',
  'Vitaliteit extra': 'Vitaliteit extra',
  'Vitality goal': 'Vitality goal',
  'Vitality tree': 'Vitality tree',
  Vitiligo: 'Vitiligo',
  Weerstand: 'Weerstand',
  'Welke skin care thuis': 'Welke skin care thuis',
  'What is it': 'What is it',
  'Whey protein': 'Whey protein',
  'White heads': 'White heads',
  'Wimpy head': 'Wimpy head',
  'Wish to come back': 'Wish to come back',
  Wish: 'Wish',
  World: 'World',
  'Wrinkles and volumeloss': 'Wrinkles and volumeloss',
  Yes: 'Yes',
  'Zeer gevoelig': 'Zeer gevoelig',
  'Zeer intolerant': 'Zeer intolerant',
  'Zestig plus': 'Zestig plus',
  'Zeventig plus': 'Zeventig plus',
  'Zonbescherming++': 'Zonbescherming++',
  'Zonbescherming+-': 'Zonbescherming+-',
  'Zonbescherming+': 'Zonbescherming+',
  'Zonbescherming--': 'Zonbescherming--',
  'Zonbescherming-': 'Zonbescherming-',
  'Zorg extra': 'Zorg extra',
  Zwangerschapsmasker: 'Zwangerschapsmasker',
  '10 Bodycontouring': '10 Bodycontouring',
  '11 Hair': '11 Hair',
  'Flat Buttocks': 'Flat Buttocks',
  'Inject Face': 'Inject Face',
};

export default translations;
