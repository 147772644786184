import { ReportingDashboard } from '../translations-types';

const translations: ReportingDashboard = {
  commentType: 'Commentaar type:',
  areYouSureYouWantToDelete: 'Weet je zeker dat je dit commentaar wilt verwijderen?',
  deleteComment: 'Verwijder commentaar',
  edited: 'aangepast',
  lastEdited: 'Laatst aangepast op {{date}}',
  addedComment: ' commentaar toegevoegd op {{date}}',
  startedFillingQuestionnaire: 'gestart met invullen vragenlijst',
  sentQuestionnaire: 'vragenlijst verstuurd',
  to: 'naar',
  answeredNQuestionsTo: 'beantwoord {{count}} vragen aan',
  customerAnsweredNQuestionsTo: 'Cliënt beantwoord {{count}} vragen aan',
  noActivityYet: 'Nog geen activiteit.',
  noCommentsYet: 'Nog geen opmerkingen',
  question: 'Vraag',
  answer: 'Antwoord',
  answeredAt: 'Beantwoord op',
  customerLoggedIn: 'Cliënt ({{email}}) succesvol ingelogd.',
  customerFailedLogin: 'Cliënt ({{email}}) inlogcode verificatie is niet geslaagd.',
  customerCodeRequest: 'Cliënt ({{email}}) heeft inlogcode aangevraagd.',
  customerUpdatedOwnProfile: 'Cliënt heeft zijn/haar profiel aangepast.',
  customerProfileUpdated: 'aangepast cliënt profiel',
  newDetailsOfCustomer: 'Nieuwe details van de cliënt',
  last7Days: 'Afgelopen 7 dagen',
  last30Days: 'Afgelopen 30 dagen',
  all: 'Alle',
  updated: 'gewijzigd',
  documentUpdated: 'Document gewijzigd',
  sentDocument: 'Document verstuurd',
  unsentDocument: 'Verwijderd document',
};

export default translations;
