import { SalonEditDrawer } from '../translations-types';

const translations: SalonEditDrawer = {
  createSalon: 'Create branch',
  editSalon: 'Edit branch',
  limitReached:
    'Unfortunately a limit of {{salons}} branches has been reached. Please update your subscription to create more branches.',
};

export default translations;
