import { Messaging } from '../translations-types';

const translations: Messaging = {
  purchaseOptions: 'Whatsapp credits purchase options',
  email: 'Email',
  whatsapp: 'Whatsapp',
  creditsBalance: 'Whatsapp credits balance',
  credits: 'credits',
  messagingType: 'Default messaging type',
  purchase: 'Purchase',
  maxLimit: 'Cannot exceed a maximum limit of {{count}} credits.',
};

export default translations;
