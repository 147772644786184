import { ConfirmationCode } from '../translations-types';

const translations: ConfirmationCode = {
  checkEmail: 'Check your email for a code',
  pleaseEnterHere: 'Please enter it here.',
  weHaveSent: 'We have sent a 6-character code to',
  confirming: 'Confirming code...',
  invalidCode: 'It looks like the given code is not valid. Please try again.',
  cantFindCode: 'Can’t find your code? It might be hiding in your spam folder!',
  resendCode: 'Resend code',
};

export default translations;
