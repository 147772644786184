import { Subscription } from '../translations-types';

const translations: Subscription = {
  currentSubscription: 'Huidig abonnement',
  upgrade: 'Aanpassen',
  succesfulUpdate: 'Het abonnement is succesvol aangepast.',
  validTo: 'Geldig tot',
  nextPayment: 'Volgende betaling',
  nextRecurringPayment: 'Volgende gemachtigde betaling',
  limits: 'Huidige abonnement limieten',
  cancelSubscription: 'Annuleren huidige abonnement',
  staySubscribed: 'Blijf geabonneerd',
  cancelTitle: 'Weet je zeker dat je je abonnement wilt annuleren?',
  cancelDescription: 'Indien je huidige plan afloopt zal het niet meer mogelijk zijn om Re-sence te gebruiken',
  noRecurringPayment: 'Geen gemachtigde betaling gepland',
  subscriptionOptions: 'Abonnement opties',
  yearly: 'Jaarlijks',
  monthly: 'Maandelijks',
  customers: 'Cliënten',
  salons: 'Vestiging(en)',
  employees: 'Medewerker(s)',
  extras: "Extra's",
  cost: 'Bedrag',
  confirmingPayment: 'Bevestig betaling',
  paymentConfirmed: 'De betaling is bevestigd',
  paymentFailed: 'Er is een fout opgetreden bij het verwerken van de betaling',
  extendSubscription: 'Verleng mijn abonnement automatisch met een betalingsmachtiging',
  extendSubscriptionTooltip: 'Het bedrag voor de volgende periode zal automatisch worden afgeschreven.',
  showExtras: "Toon extra's",
  hideExtras: "Verberg extra's",
  optionNotAvailable: 'Het is niet mogelijk om te upgraden van een jaarlijks abonnement naar een maandelijks abonnement.',
  pricesExcludeVAT: 'Bedragen zijn exclusief BTW',
  pleaseSelectAutoExtend: 'Kies of een abonnement automatisch mag worden verlengd.',
  noOptionSelected: 'Geen optie geselecteerd',
  subscriptionHasExpiredTitle: 'Abonnement is verlopen.',
  subscriptionHasExpiredDescriptionEmployee:
    'Je abonnementsperiode is beëindigd. Om verder te gaan met het gebruik van Re-sence kun je je abonnement verlengen.',
  subscriptionHasExpiredDescriptionAdmin:
    'Je abonnementsperiode is beëindigd. Om verder te gaan met het gebruik van Re-sence kun je je abonnement verlengen.',
};

export default translations;
