import { AdminChangePassword } from '../translations-types';

const translations: AdminChangePassword = {
  passwordHasBeenChanged: 'Password has been successfully changed.',
  passwordHasBeenSet: 'Password has been successfully set.',
  changePassword: 'Change password',
  setNewPassword: 'Set new password',
  confirmPassword: 'Confirm password',
  user: 'User: {{email}}',
};

export default translations;
