import { NewSubscription } from '../translations-types';

const translations: NewSubscription = {
  purchasedSubscription: 'Succesvol ingeschreven voor abonnement {{type}}!',
  createNewSalon: 'Maak je nieuwe vestiging aan',
  enterSalonDetails: 'Geef hier je details op voor je vestiging.',
  createSalon: 'Aanmaken mijn vestiging',
  paymentPending: 'Betaling in behandeling...',
  startNewSubscription: 'Start een nieuw abonnement',
  continueToPayment: 'Doorgaan naar afrekenen',
  month: 'Maand',
  year: 'Jaar',
  savings: 'besparing',
  recommended: 'Aanbevolen',
  saveUpTo: 'BESPAAR WEL TOT',
};

export default translations;
