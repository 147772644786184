import { RoleEditDrawer } from '../translations-types';

const translations: RoleEditDrawer = {
  createRole: 'Aanmaken rol',
  editRole: 'Wijzigen rol',
  addRole: 'Toevoegen rol',
  permissions: 'Rechten',
  roleName: 'Naam rol',
};

export default translations;
