import { CreateOrganisation } from '../translations-types';

const translations: CreateOrganisation = {
  createOrganization: 'Create organisation',
  subscription: 'Subscription',
  successfullyCreated: 'Successfully created organisation',
  successfullyUpdated: 'Successfully updated organisation',
  createOrganizationSubtitle: 'Organisation description.',
  legalCompanyName: 'Legal company name',
  companyNameToUse: 'Company name to use',
  organizationWebAddress: 'Organisation web address',
  organization: 'Organisation',
  slugTooltip: 'Will be used to access your organisation in the address bar.',
  youAgree: 'By registering you will agree to our',
  termsAndConditions: 'terms and conditions',
  vatNumber: 'VAT number',
  invoiceLanguage: 'Invoice language',
  country: 'Country',
  address: 'Address line 1',
  address2: 'Address line 2',
  postalCode: 'Postal code',
  city: 'City',
  showDifferentName: 'Show customers a different name',
  companyNameToUseTooltip: 'This is the name your customers will see.',
};

export default translations;
