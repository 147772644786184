import { CustomFieldEditDrawer } from '../translations-types';

const translations: CustomFieldEditDrawer = {
  createField: 'Aanmaken veld',
  editField: 'Wijzigen veld',
  addCustomField: 'Toevoegen aangepast veld',
  areYouSureYouWantToDelete: 'Weet je zeker dat je aangepast veld "{{name}}" wilt verwijderen?',
  deleteCustomField: 'Verwijder aangepast veld',
};

export default translations;
