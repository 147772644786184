import { AdminLogin } from '../translations-types';

const translations: AdminLogin = {
  signIn: 'Aanmelden',
  logIn: 'Inloggen',
  dontHaveAccount: `Nog geen account?`,
  signUp: 'Registreren',
  forgotPassword: 'Wachtwoord vergeten?',
};

export default translations;
