import i18n from 'i18next';
import { initReactI18next, useTranslation as useTranslationOriginal } from 'react-i18next';
import { useCallback } from 'react';
import LocalStorageService from 'common/services/local-storage-service';
import { SupportedLanguage } from 'common/api/generated-api';
import TimeAgo, { LocaleData } from 'javascript-time-ago';
import timeAgoEn from 'javascript-time-ago/locale/en';
import timeAgoNl from 'javascript-time-ago/locale/nl';
import { TranslationsMap } from './types';
import { translate, translateWithOptions, translateUnsafe } from './utils';
import en from './en';
import nl from './nl';
import { AllTranslations } from './translations-types';

const timeAgoLocale: { [key in SupportedLanguage]: LocaleData } = {
  [SupportedLanguage.En]: {
    ...timeAgoEn,
    now: {
      now: 'just now',
    },
  },
  [SupportedLanguage.Nl]: {
    ...timeAgoNl,
    now: {
      now: 'zojuist',
    },
  },
};

export const setupTranslations = async () => {
  const language = LocalStorageService.getUserLanguage();

  TimeAgo.addDefaultLocale(timeAgoLocale[language]);

  await i18n.use(initReactI18next).init({
    lng: language,
    ns: Object.keys(en),
    resources: {
      en,
      nl,
    },
    nsSeparator: '.',
    initImmediate: true,
  });
};

export const i18nProvider = i18n;

export type TranslateFunction<AllTranslations> = TranslationsMap<AllTranslations>['translate'];
export type TranslateWithOptionsFunction<AllTranslations> = TranslationsMap<AllTranslations>['translateWithOptions'];
export type TranslateUnsafeFunction = typeof translateUnsafe;

export const t: TranslateFunction<AllTranslations> = translate;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const to: TranslateWithOptionsFunction<AllTranslations> = translateWithOptions as any;
export const tu = translateUnsafe;

export const useTranslation = () => {
  const { t: tOriginal } = useTranslationOriginal();

  return {
    t: useCallback<TranslateFunction<AllTranslations>>(
      // eslint-disable-next-line
      (...keys: [any, any]) => {
        return translate(...keys);
      },
      [tOriginal], // eslint-disable-line
    ),
    to: useCallback<TranslateWithOptionsFunction<AllTranslations>>(
      // eslint-disable-next-line
      (...keys: [any, any]) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return (translateWithOptions as any)(...keys);
      },
      [tOriginal], // eslint-disable-line
    ),
    tu: useCallback<TranslateUnsafeFunction>(
      // eslint-disable-next-line
      (...keys: [any, any]) => {
        return translateUnsafe(...keys);
      },
      [tOriginal], // eslint-disable-line
    ),
  };
};
