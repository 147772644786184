import { OrganisationSettings } from '../translations-types';

const translations: OrganisationSettings = {
  employeeName: 'Naam medewerker',
  activityDate: 'Datum activiteit',
  activityType: 'Activiteit type',
  employeesActivity: 'Overzicht uitgevoerde acties medewerkers',
  activityData: 'Activiteit informatie',
  contactEmployee: 'Communicatie versturen naar',
};

export default translations;
