import { Payments } from '../translations-types';

const translations: Payments = {
  amount: 'Amount',
  paidAt: 'Paid at',
  status: 'Status',
  name: 'Name',
  downloadInvoice: 'Download invoice',
  details: 'Details',
};

export default translations;
