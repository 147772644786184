import { AdminRequestPasswordReset } from '../translations-types';

const translations: AdminRequestPasswordReset = {
  resetPassword: 'Herstel wachtwoord',
  success: 'Gelukt!',
  linkHasBeenSent: 'Wachtwoord herstel link is verstuurd naar',
  backToLogin: 'Terug naar inloggen',
};

export default translations;
