import { NewSubscription } from '../translations-types';

const translations: NewSubscription = {
  purchasedSubscription: 'Successfully purchased {{type}} subscription!',
  createNewSalon: 'Create your new branch',
  enterSalonDetails: 'Now you just need to enter details for your branch.',
  createSalon: 'Create my branch',
  paymentPending: 'Payment is pending...',
  startNewSubscription: 'Start a new subscription',
  continueToPayment: 'Continue to payment',
  month: 'Month',
  year: 'Year',
  savings: 'savings',
  recommended: 'Recommended',
  saveUpTo: 'SAVE UP TO',
};

export default translations;
