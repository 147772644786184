import { ConditionValueOption, MappingElementLabelWidth } from 'common/api/generated-api';

export enum DashboardType {
  Wishes = 'Wishes',
  Wishes2 = 'Wishes2',
  Skin = 'Skin',
  Vitality = 'Vitality',
  Reporting = 'Reporting',
  Advice = 'Advice',
  Documents = 'Documents',
}

export type DashboardConfiguration =
  | Wishes2DashboardConfiguration
  | VitalityDashboardConfiguration
  | WishesDashboardConfiguration
  | SkinDashboardConfiguration;

export type VitalityDashboardConfiguration = {
  id: string;
  type: DashboardType.Vitality;
  elements: MappingElement[];
};

export type WishesDashboardConfiguration = {
  id: string;
  type: DashboardType.Wishes;
  elements: MappingElement[];
};

export type Wishes2DashboardConfiguration = {
  id: string;
  type: DashboardType.Wishes2;
  elements: MappingElement[];
};

export type SkinDashboardConfiguration = {
  id: string;
  type: DashboardType.Skin;
  elements: MappingElement[];
};

export enum MappingElementType {
  Direct = 'Direct',
  Conditional = 'Conditional',
  Persistent = 'Persistent',
}

export enum LabelPosition {
  Bottom = 'bottom',
  Right = 'right',
  Left = 'left',
  Top = 'top',
  NoLabel = 'noLabel',
}

export type BaseMappingElement = {
  groupName: string;
  elementName: string;
  questionnaireId?: string;
  questionId?: string;
  image?: string;
  labelPosition: LabelPosition;
  labelPostfix?: string;
  labelWidth?: MappingElementLabelWidth;
};

export type ConditionalMappingElement = BaseMappingElement & {
  type: MappingElementType.Conditional;
  value: string | ConditionValueOption;
};

export type DirectMappingElement = BaseMappingElement & {
  type: MappingElementType.Direct;
};

export type PersistentMappingElement = BaseMappingElement & {
  type: MappingElementType.Persistent;
};

export type MappingElement = ConditionalMappingElement | DirectMappingElement | PersistentMappingElement;

export type MappingElementUpdateType = {
  type: MappingElementType;
  value: string;
  groupName: string;
  elementName: string;
  questionnaireId: string;
  questionId: string;
  image?: string;
};

export type DashboardConfigurationUpdateType = Omit<DashboardConfiguration, 'id' | 'rows' | 'groups'> & {
  id?: string;
  rows: MappingElementUpdateType[];
  groups: MappingElementUpdateType[];
};

export type DashboardConfigurationsUpdateRequest = {
  mappings: DashboardConfigurationUpdateType[];
};
