import { CreateCustomerDrawer } from '../translations-types';

const translations: CreateCustomerDrawer = {
  customer: 'Cliënt',
  hasBeenCreated: 'is succesvol aangemaakt.',
  createNewCustomer: 'Aanmaken nieuwe cliënt',
  customerAlreadyExistsExplanation:
    'Cliënt bestaat reeds (mogelijk in een andere salon). Opslaan zal de toegewezen salons wijzigen voor deze cliënt.',
  limitReached:
    'De limiet van {{customers}} cliënten is bereikt. Om meer cliënten te kunnen toevoegen moet je je abonnement aanpassen.',
};

export default translations;
