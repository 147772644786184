import { Navigation } from '../translations-types';

const translations: Navigation = {
  customers: 'Customers',
  employees: 'Employees',
  questionnaires: 'Questionnaires',
  settings: 'Settings',
  import: 'Import customers',
  subscriptions: 'Subscriptions',
  payments: 'Payments',
  messaging: 'Whatsapp credits',
  salons: 'Branches',
  roles: 'Roles',
  customFields: 'Custom fields',
  organisationSettings: 'Organisation settings',
  advice: 'Grow',
  adviceConditions: 'Grow module conditions',
  adviceGroups: 'Grow module groups',
  trainings: 'Blueprint',
  userManual: 'User manual',
  courses: 'Academy',
  documentsAndConsentForms: 'Documents & Consent forms',
};

export default translations;
