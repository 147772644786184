import styled, { css } from 'styled-components';
import React from 'react';

const StyledWrapper = styled.div`
  height: 100%;

  * {
    font-family: 'Belleza' !important;
  }
`;

const ContentWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  top: 30%;
  padding: 50px 0;

  ${(props) => {
    if (props.theme.responsive) {
      return css`
        justify-content: space-between;
        top: 0px;
      `;
    }

    return css`
      top: 30%;
    `;
  }}

  a, button {
    margin-top: 16px;
    margin: 16px auto;
  }
`;
const TextWrapper = styled.div`
  text-align: center;
`;
const Title = styled.div`
  font-size: 120px;
  font-weight: 500;
`;
const SubTitle = styled.div`
  font-size: 26px;
`;
const StyledImage = styled.img`
  width: 100%;
  object-fit: cover;
  object-position: 25% 50%;
  min-height: 100%;
`;

interface OwnProps {
  title: string;
  subTitle: string;
  button: React.ReactNode;
}

const FullSizeError = ({ title, subTitle, button }: OwnProps) => {
  return (
    <StyledWrapper>
      <StyledImage src='/assets/error-background.png' />
      <ContentWrapper>
        <TextWrapper>
          <Title>{title}</Title>
          <SubTitle>{subTitle}</SubTitle>
        </TextWrapper>
        {button}
      </ContentWrapper>
    </StyledWrapper>
  );
};

export default FullSizeError;
