import { QuestionnairesOverview } from '../translations-types';

const translations: QuestionnairesOverview = {
  complete: 'complete',
  completed: 'Completed',
  receivedAt: 'Received at',
  completedQuestionnaires: 'Completed Questionnaires',
  pendingQuestionnaires: 'Pending Questionnaires',
  noQuestionnairesYet: 'No questionnaires yet',
  notStarted: 'Not started',
  lastAnswer: 'Last answer',
  received: 'Received',
};

export default translations;
