import { Button } from 'antd';
import { useTranslation } from 'common/i18n';
import FullSizeError from './FullSizeError';

const ApplicationCrashed = () => {
  const { t } = useTranslation();

  return (
    <FullSizeError
      title={t('validation', 'oops')}
      subTitle={t('validation', 'anUnexpectedErrorHasOccured')}
      button={
        <Button type='primary' onClick={() => window.location.reload()}>
          {t('common', 'reload')}
        </Button>
      }
    />
  );
};

export default ApplicationCrashed;
