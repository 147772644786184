import { AppSeason } from 'common/types/theme';
import { APP_SEASON } from 'common/app-constants';
import getSpringTheme from './springTheme';
import getSummerTheme from './summerTheme';
import getAutumnTheme from './fallTheme';
import getWinterTheme from './winterTheme';
import { Theme } from './types';

const themesMap: Record<
  AppSeason,
  ({
    responsive,
    windowHeight,
    windowWidth,
    condensedMode,
  }: {
    responsive: boolean;
    condensedMode: boolean;
    windowWidth: number;
    windowHeight: number;
  }) => Theme
> = {
  [AppSeason.Spring]: getSpringTheme,
  [AppSeason.Summer]: getSummerTheme,
  [AppSeason.Fall]: getAutumnTheme,
  [AppSeason.Winter]: getWinterTheme,
};

export const getTheme = (props: { responsive: boolean; condensedMode: boolean; windowWidth: number; windowHeight: number }) =>
  themesMap[APP_SEASON](props);
