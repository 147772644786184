import { DiscountCode } from '../translations-types';

const translations: DiscountCode = {
  discountCode: 'Kortingscode',
  appliedDiscount: 'Toegepaste kortingscode',
  discountApplied: 'Korting toegepast',
  invalidOrExpired: 'Ongeldige of verlopen kortingscode',
  haveADiscount: 'Heeft u een kortingscode?',
};

export default translations;
