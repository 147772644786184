import { SalonEditDrawer } from '../translations-types';

const translations: SalonEditDrawer = {
  createSalon: 'Aanmaken vestiging',
  editSalon: 'Wijzigen vestiging',
  limitReached:
    'De limiet van {{salons}} vestigingen is bereikt. Om meer vestigingen te kunnen aanmaken kun je je abonnement aanpassen',
};

export default translations;
