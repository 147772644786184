import { AdminHeader } from '../translations-types';

const translations: AdminHeader = {
  addCustomer: 'Add customer',
  myProfile: 'My profile',
  signOut: 'Sign out',
  theme: 'Theme',
  trainings: 'Blueprint',
  condensedMode: 'Condensed mode',
  condensedModeOff: 'Turn off condensed mode',
};

export default translations;
