import { AudioModal } from '../translations-types';

const translations: AudioModal = {
  errorOccuredConverting: 'An error occured while converting audio file.',
  attachRecording: 'Attach recording',
  recording: 'Recording...',
  createRecording: 'Create recording',
  stopRecording: 'Stop recording',
};

export default translations;
