import { Permissions } from '../translations-types';

const translations: Permissions = {
  organisationRoles: 'Organization roles',
  salonsAccess: 'Branch access',
  rolesFor: 'Roles for',
  readCustomers: 'Read customers',
  manageCustomers: 'Manage customers',
  manageEmployees: 'Manage employees',
  manageQuestionnaires: 'Manage questionnaires',
  readEmployees: 'Read employees',
  readQuestionnaires: 'Read questionnaires',
  managePermissions: 'Manage permissions',
  manageSalons: 'Manage branches',
  manageSubscription: 'Manage subscription',
  ownOrganisation: 'Own organization',
  readSalons: 'Read branches',
  readSubscription: 'Read subscription',
  manageTrainings: 'Manage blueprint',
  description: {
    managePermissions: 'Enables user to modify organisation roles and assign them to any user.',
    manageSalons: 'Enables user to manage all branches within the organisation.',
    manageSubscription: 'Enables user to change subscription type and execute payments.',
    ownOrganisation: 'Enables user to execute actions within the organisation.',
    readSalons: 'Enables user to read all branches within the organisation.',
    readSubscription: 'Enables user to view subscription details and payments.',
    manageTrainings: 'Enables user to create/update/delete blueprint courses and modules.',
    manageDocuments: 'Enables user to create/update/delete documents and consent forms.',
  },
  manageAdvice: 'Manage Grow modules',
  readAdvice: 'Read Grow modules',
  manageDocuments: 'Manage documents & consent forms',
};

export default translations;
