import { AppSeason } from 'common/types/theme';
import getBaseTheme from './baseTheme';
import { pSBC } from './color-converter';
import { Theme } from './types';

const primaryColor = '#C38A4E';
const getTheme = ({
  condensedMode,
  responsive,
  windowHeight,
  windowWidth,
}: {
  condensedMode: boolean;
  responsive: boolean;
  windowWidth: number;
  windowHeight: number;
}): Theme => {
  const baseTheme = getBaseTheme({ primaryColor, responsive, condensedMode, windowWidth, windowHeight });

  return {
    ...baseTheme,
    name: AppSeason.Summer,
    colors: {
      ...baseTheme.colors,
      primary: primaryColor,
      sideNavigation: '#D8AA79',
      sideNavigationTop: '#896137',
      sideNavigationHover: '#C2986C',
      coloredText: '#793E19',
      primaryTransparent: (transparency) => `rgba(195, 138, 78, ${transparency})`,
      primaryDarker: (darkness) => pSBC(-1 * darkness, primaryColor),
      primaryLighter: (lightness) => pSBC(lightness, primaryColor),
    },
  };
};

export default getTheme;
