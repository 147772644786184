import { MyProfileDrawer } from '../translations-types';

const translations: MyProfileDrawer = {
  editMyProfile: 'Edit my profile',
  changePassword: 'Change password',
  currentPassword: 'Current password',
  confirmPassword: 'Confirm password',
  successfullyUpdatedProfile: 'Successfully updated profile.',
};

export default translations;
