import { Questionnaires } from '../translations-types';

const translations: Questionnaires = {
  newOption: 'Nieuwe antwoordoptie',
  options: 'Opties',
  option: 'Optie',
  startTyping: 'Begin met typen voor het toevoegen van de nieuwe optie',
  question: 'Vraag',
  type: 'Soort',
  isRequired: 'Vereist?',
  allowMultipleValues: 'Toestaan meerdere opties?',
  allowCustomValue: 'Kiezen toestaan van een aangepaste waarde?',
  displayLogic: 'Weergave logica?',
  displayLogicTooltip: 'De vraag conditioneel weergeven!',
  editQuestionnaire: 'Wijzigen vragenlijst',
  createQuestionnaire: 'Aanmaken vragenlijst',
  viewQuestionnaire: 'Bekijken vragenlijst',
  addQuestion: '+ Toevoegen vraag',
  rating: 'Beoordeling',
  containsNumber: 'Bevat nummer',
  containsDate: 'Bevat datum',
  containsText: 'Bevat tekst',
  containsAnyText: 'Bevat willekeurige tekst',
  elementType: 'Element type',
  directElementDescription: 'Direct - het element wordt altijd getoond met het antwoord op de vraag.',
  conditionalElementDescription: 'Voorwaardelijk - het element wordt alleen getoond als aan de voorwaarde wordt voldaan.',
  persistentElementDescription: 'Permanent - het element wordt altijd getoond bij een permanente tekst of plaatje.',
  questionnaire: 'Vragenlijst',
  questionnaires: 'Vragenlijsten',
  labelPosition: 'Label positie',
  label: 'Label',
  labelPostfix: 'Label achtervoegsel',
  labelPostfixTooltip: 'Een stukje tekst dat achter het de label-tekst wordt geplaatst, b.v. "X per dag".',
  maxLabelWidth: 'Maximale label breedte',
  chooseImage: 'Kies plaatje',
  editOverview: 'Wijzig klant vragenlijst overzicht',
  newQuestion: 'Nieuwe vraag',
  successfullyCreated: 'Succesvol aangepast vragenlijst.',
  successfullyUpdated: 'Succesvol aangemaakte vragenlijst.',
  questionnaireName: 'Naam vragenlijst',
  questionsCount: 'Aantal vragen',
  addQuestionnaire: 'Toevoegen vragenlijst',
  variant: 'Variant',
  body: 'Tekst',
  image: 'Plaatje',
  videoUrl: 'Video Url',
  onlyYtVimeo: 'YouTube en Vimeo videos worden ondersteund.',
  messageTemplate: 'Bericht template',
  hidden: 'Verborgen?',
  questionXName: 'Vraag {{number}} naam',
  questionXBody: 'Vraag {{number}} tekst',
  questionXOptionYCustomerTitle: 'Vraag {{number}} optie {{number2}} cliënt titel',
  questionXOptionYEmployeeTitle: 'Vraag {{number}} optie {{number2}} medewerker titel',
};

export default translations;
