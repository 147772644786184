import { Navigation } from '../translations-types';

const translations: Navigation = {
  customers: 'Cliënten',
  employees: 'Medewerker',
  questionnaires: 'Vragenlijsten',
  settings: 'Instellingen',
  import: 'Importeren cliënten',
  subscriptions: 'Abonnementen',
  payments: 'Betalingen',
  messaging: 'Whatsapp krediet',
  salons: 'Vestiging(en)',
  roles: 'Rollen',
  customFields: 'Aangepaste velden',
  organisationSettings: 'Organisatie instellingen',
  advice: 'Groei',
  adviceConditions: 'Groei-module condities',
  adviceGroups: 'Groei-module groepen',
  trainings: 'Blauwdruk',
  userManual: 'Handleiding',
  courses: 'Academie',
  documentsAndConsentForms: 'Documenten & Instemmings-formulieren',
};

export default translations;
