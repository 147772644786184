import { Messaging } from '../translations-types';

const translations: Messaging = {
  purchaseOptions: 'Whatsapp krediet opties',
  email: 'E-mail',
  whatsapp: 'Whatsapp',
  creditsBalance: 'Whatsapp krediet balans',
  credits: 'credits',
  messagingType: 'Voorkeur bericht type nieuwe cliënt',
  purchase: 'Toevoegen',
  maxLimit: 'De maximale limiet van {{count}} Whatsapp krediet kan niet worden overschreden.',
};

export default translations;
