import { QuestionnairesOverview } from '../translations-types';

const translations: QuestionnairesOverview = {
  complete: 'Afronden',
  completed: 'Afgerond',
  receivedAt: 'Ontvangen op',
  completedQuestionnaires: 'Afgeronde vragenlijsten',
  pendingQuestionnaires: 'Gestarte vragenlijsten',
  noQuestionnairesYet: 'Nog geen vragenlijsten',
  notStarted: 'Nog niet gestart',
  lastAnswer: 'Laatst gegeven antwoord',
  received: 'Ontvangen',
};

export default translations;
