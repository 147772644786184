import { CsvCustomersImportModal } from '../translations-types';

const translations: CsvCustomersImportModal = {
  successfully: 'Successfully',
  createdN: 'Created {{count}} customer(-s)',
  updatedN: 'Updated {{count}} customer(-s)',
  skippedNDuplicates: '{{count}} skipped due to duplicates in file',
  skippedNEmptyMail: '{{count}} skipped due to empty email',
  nNewCustomers: '{{count}} new customers',
  nExistingCustomers: '{{count}} existing customers',
  emailColumnMustBeSelected: 'Email column must be selected',
  fileData: 'File data',
  importDetails: 'Import details',
  importCustomers: 'Import customers from CSV',
  import: 'Import',
  fileContainsHeader: 'File contains header',
  delimiter: 'Delimiter',
  uploadFile: 'Upload file',
  importOptions: 'Import options',
  column: 'Column',
  importNewUsers: 'Import new users',
  importExistingUsers: 'Import existing users',
};

export default translations;
