import {
  CustomerGender,
  EmployeeActivityType,
  MappingElementLabelWidth,
  CustomerActivityType,
  QuestionnaireType,
  QuestionType,
  SupportedLanguage,
  CustomerCommentType,
  AdviceType,
  SubscriptionOptionFeature,
  OrganisationMessagingType,
  CustomerConsentStatus,
  DocumentType,
} from 'common/api/generated-api';
import { DashboardType, LabelPosition, MappingElementType } from 'common/types/dashboard-configuration';
import { Types } from '../translations-types';

const translations: Types = {
  [QuestionnaireType.CustomerQuestionnaire]: 'Cliënt vragenlijst',
  [QuestionnaireType.EmployeeQuestionnaire]: 'Medewerker vragenlijst',
  [QuestionType.Boolean]: 'Selectievakje',
  [QuestionType.Date]: 'Datum',
  [QuestionType.Image]: 'Afbeeldingen',
  [QuestionType.Number]: 'Nummer',
  [QuestionType.Option]: 'Meerkeuze',
  [QuestionType.Rating]: 'Beoordeling',
  [QuestionType.String]: 'Tekst',
  [QuestionType.Information]: 'Informatie',
  [CustomerActivityType.AnswerUpdated]: 'Antwoord aangepast',
  [CustomerActivityType.SubmissionCreated]: 'Vragenlijst verzonden',
  [CustomerActivityType.Login]: 'Inloggen',
  [CustomerActivityType.FailedLogin]: 'Inloggen mislukt',
  [CustomerActivityType.CodeRequest]: 'Inlogcode aanvraag',
  [CustomerActivityType.ProfileUpdate]: 'Profiel aangepast',
  [CustomerActivityType.DocumentStatusUpdated]: 'Document status aangepast',
  [CustomerActivityType.DocumentSent]: 'Document verstuurd',
  [CustomerActivityType.DocumentDelete]: 'Document verwijderd',
  [LabelPosition.Bottom]: 'Onder',
  [LabelPosition.Left]: 'Links',
  [LabelPosition.Right]: 'Rechts',
  [LabelPosition.Top]: 'Boven',
  [LabelPosition.NoLabel]: 'Geen label',
  [SupportedLanguage.Nl]: 'Nederlands',
  [SupportedLanguage.En]: 'Engels',
  [MappingElementType.Conditional]: 'Conditional',
  [MappingElementType.Direct]: 'Direct',
  [MappingElementType.Persistent]: 'Persistent',
  [MappingElementLabelWidth.Default]: 'Default',
  [MappingElementLabelWidth.Short]: 'Short',
  [MappingElementLabelWidth.Long]: 'Long',
  [MappingElementLabelWidth.Max]: 'Max',
  [DashboardType.Reporting]: 'Klantkaart',
  [DashboardType.Skin]: 'Huid',
  [DashboardType.Vitality]: 'Vitaliteit',
  [DashboardType.Wishes]: 'Wensen',
  [DashboardType.Wishes2]: 'Wensen+',
  [DashboardType.Advice]: 'Groei',
  [DashboardType.Documents]: 'Documenten',
  [CustomerGender.Female]: 'Vrouw',
  [CustomerGender.Male]: 'Man',
  [CustomerGender.Undefined]: 'Genderneutraal',
  [EmployeeActivityType.CreateRole]: 'Aanmaken rol',
  [EmployeeActivityType.CustomerCommentAdd]: 'Cliënt commentaar toevoegen',
  [EmployeeActivityType.CustomerCommentDelete]: 'Cliënt commentaar verwijderen',
  [EmployeeActivityType.CustomerCommentUpdate]: 'Cliënt commentaar wijzigen',
  [EmployeeActivityType.CustomerCreate]: 'Cliënt aanmaken',
  [EmployeeActivityType.CustomerSkinAnalysisUpdate]: 'Cliënt huidanalyse wijzigen',
  [EmployeeActivityType.CustomerUpdate]: 'Cliënt wijzigen',
  [EmployeeActivityType.CustomersImport]: 'Cliënt importeren',
  [EmployeeActivityType.CustomersSearch]: 'Cliënt zoeken',
  [EmployeeActivityType.DashboardConfigurationUpdate]: 'Dashboard configuratie wijzigen',
  [EmployeeActivityType.EmployeeCreate]: 'Medewerker aanmaken',
  [EmployeeActivityType.EmployeeUpdate]: 'Medewerker wijzigen',
  [EmployeeActivityType.OrganisationUpdate]: 'Organisatie wijzigen',
  [EmployeeActivityType.PasswordResetRequest]: 'Wachtwoord herstel verzoek',
  [EmployeeActivityType.QuestionnaireCreate]: 'Vragenlijst aanmaken',
  [EmployeeActivityType.QuestionnaireUpdate]: 'Vragenlijst wijzigen',
  [EmployeeActivityType.SalonCreate]: 'Vestiging aanmaken',
  [EmployeeActivityType.SalonUpdate]: 'Vestiging wijzigen',
  [EmployeeActivityType.SuccessfulPasswordReset]: 'Wachtwoord succesvol gereset',
  [EmployeeActivityType.UpdateRole]: 'Wijzigen rol',
  [EmployeeActivityType.CreatePayment]: 'Aanmaken betaling',
  [EmployeeActivityType.CancelSubscription]: 'Annuleren abonnement',
  [EmployeeActivityType.UpdateSubscription]: 'Abonnement aangepast',
  [EmployeeActivityType.CustomerHardDelete]: 'Cliënt definitief verwijderen',
  [EmployeeActivityType.CustomerConsentSend]: 'Instemmingsformulier verstuurd',
  [EmployeeActivityType.CustomerDocumentSend]: 'Informatiedocument verstuurd',
  [EmployeeActivityType.CustomerDocumentDelete]: 'Informatiedocument verwijderd',
  [CustomerCommentType.OnPoint]: 'Aanduiden',
  [CustomerCommentType.Products]: 'Producten',
  [CustomerCommentType.SkinPlan]: 'Huidplan',
  [CustomerCommentType.Treatment]: 'Behandeling',
  [EmployeeActivityType.WhatsappCreditsPaymentCreate]: 'Whatsapp krediet betaling aangemaakt',
  [EmployeeActivityType.PaidCousePaymentCreate]: 'Cursus betaling aangemaakt',
  [AdviceType.CustomerAdvice]: 'Cliënt groei-module',
  [AdviceType.EmployeeAdvice]: 'Medewerker groei-module',
  [SubscriptionOptionFeature.Advice]: 'Groei-module omschrijving',
  [SubscriptionOptionFeature.Wishes]: 'Wensen-module omschrijving',
  [SubscriptionOptionFeature.Skin]: 'Huid-module omschrijving',
  [SubscriptionOptionFeature.Vitality]: 'Vitaliteit-module omschrijving',
  [SubscriptionOptionFeature.Fingerprint]: 'Vingerafdruk-module omschrijving',
  [SubscriptionOptionFeature.Salon]: 'Vestiging(en)',
  [SubscriptionOptionFeature.Employee]: 'Medewerker(s)',
  [SubscriptionOptionFeature.Customer]: 'Cliënten',
  [OrganisationMessagingType.Email]: 'E-mail',
  [OrganisationMessagingType.Whatsapp]: 'Whatsapp',
  [CustomerConsentStatus.Accepted]: 'Geaccepteerd',
  [CustomerConsentStatus.Declined]: 'Geweigerd',
  [CustomerConsentStatus.NotSeen]: 'Niet geopend',
  [CustomerConsentStatus.Seen]: 'Gezien maar nog niet geaccepteerd',
  [DocumentType.ConsentForm]: 'Instemmingsformulier',
  [DocumentType.Document]: 'Informatiedocument',
};

export default translations;
