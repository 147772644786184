import { ConfirmationCode } from '../translations-types';

const translations: ConfirmationCode = {
  checkEmail: 'Controleer je email voor de inlogcode',
  pleaseEnterHere: 'Vul de inlogcode hier in.',
  weHaveSent: 'We hebben een 6-karakter code verzonden naar',
  confirming: 'Bevestig inlogcode...',
  invalidCode: 'Het lijkt dat de ingevoerde inlogcode niet geldig is. Probeer het nogmaals.',
  cantFindCode: 'Inlogcode niet ontvangen? Controleer of deze in je spam folder is beland!',
  resendCode: 'Verstuur inlogcode opnieuw',
};

export default translations;
