import { Salons } from '../translations-types';

const translations: Salons = {
  salonName: 'Branch name',
  customersCount: 'Customers count',
  employeesCount: 'Employees count',
  addSalon: 'Add branch',
};

export default translations;
