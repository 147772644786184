import { RoleEditDrawer } from '../translations-types';

const translations: RoleEditDrawer = {
  createRole: 'Create role',
  editRole: 'Edit role',
  addRole: 'Add role',
  permissions: 'Permissions',
  roleName: 'Role name',
};

export default translations;
