import { Courses } from '../translations-types';

const translations: Courses = {
  courses: 'Cursussen',
  addCourse: '+ Toevoegen cursus',
  addSegment: '+ Toevoegen segment',
  addModule: '+ Toevoegen module',
  editModule: 'Aanpassen module',
  createModule: 'Maken module',
  moduleDeleteConfirmation: 'Weet u het zeker? De module met alle segmenten zullen worden verwijderd.',
  courseDeleteConfirmation: 'Weet u het zeker? De cursus met alle modules en segmenten zullen worden verwijderd.',
  deleteModule: 'Verwijder module',
  enterText: 'Voer tekst in...',
  modules: 'Modules',
  createCourse: 'Aanmaken cursus',
  editCourse: 'Wijzigen cursus',
  deleteCourse: 'Verwijderen cursus',
  noModules: 'Nog geen modules aanwezig',
  noCourses: 'Nog geen cursussen aanwezig',
  noSegments: 'Nog geen segmenten aanwezig',
  cost: 'Bedrag',
  discountCodes: 'Kortingscodes',
  enterDescription: 'Geef een omschrijving',
  segmentX: 'Segment {{number}}',
};

export default translations;
