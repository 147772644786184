import { CustomFieldEditDrawer } from '../translations-types';

const translations: CustomFieldEditDrawer = {
  createField: 'Create field',
  editField: 'Edit field',
  addCustomField: 'Add custom field',
  areYouSureYouWantToDelete: 'Are you sure you want to delete custom field "{{name}}"?',
  deleteCustomField: 'Delete custom field',
};

export default translations;
