import { AppSeason } from 'common/types/theme';
import getBaseTheme from './baseTheme';
import { pSBC } from './color-converter';
import { Theme } from './types';

const primaryColor = '#96B69A';
const getTheme = ({
  responsive,
  condensedMode,
  windowHeight,
  windowWidth,
}: {
  responsive: boolean;
  condensedMode: boolean;
  windowWidth: number;
  windowHeight: number;
}): Theme => {
  const baseTheme = getBaseTheme({ primaryColor, responsive, condensedMode, windowWidth, windowHeight });
  return {
    ...baseTheme,
    name: AppSeason.Spring,
    colors: {
      ...baseTheme.colors,
      primary: primaryColor,
      sideNavigation: '#B8CDBB',
      sideNavigationTop: '#697F6C',
      sideNavigationHover: '#A6BDA3',
      coloredText: '#43503F',
      primaryTransparent: (transparency) => `rgba(150, 182, 154, ${transparency})`,
      primaryDarker: (darkness) => pSBC(-1 * darkness, primaryColor),
      primaryLighter: (lightness) => pSBC(lightness, primaryColor),
    },
  };
};

export default getTheme;
