import { Payments } from '../translations-types';

const translations: Payments = {
  amount: 'Bedrag',
  paidAt: 'Betaald op',
  status: 'Status',
  name: 'Naam',
  downloadInvoice: 'Download factuur',
  details: 'Details',
};

export default translations;
