import { Validation } from '../translations-types';

const translations: Validation = {
  validEmailIsRequired: 'Geldig e-mailadres is benodigd',
  youMustSelectASalon: 'Kies een vestiging',
  currentPasswordRequired: 'Wachtwoord is benodigd',
  passwordConfirmationRequired: 'Geldig wachtwoord is benodigd',
  passwordRequirements:
    'Wachtwoord moet minimaal één kleine letter bevatten, één hoofdletter, één nummer en moet minstens uit 8 karakters bestaan',
  passwordsDoNotMatch: 'Wachtwoorden komen niet overeen!',
  legalCompanyNameIsRequired: 'Officieel geregistreerde bedrijfsnaam is benodigd',
  companyNameIsAlreadyInUse: 'Deze bedrijfsnaam is reeds in gebruik',
  companyNameToUseIsRequired: 'Alternatieve bedrijfsnaam is benodigd',
  organizationWebAddressIsRequired: 'Organisatie url naam is benodigd',
  salonNameRequired: 'Naam vestiging is benodigd',
  roleNameRequired: 'Naam rol is benodigd',
  displayNameRequired: 'Schermnaam is benodigd',
  propertyNameRequired: 'Naam eigenschap is benodigd',
  invoiceLanguageIsRequired: 'Factuur taal is benodigd',
  languageIsRequired: 'Taal is benodigd',
  tooManyRequests: 'Er zijn teveel poging gedaan, probeer het over 15 min nog eens.',
  phoneNumber: 'Het telefoonnummer moet in het internationale format zijn. Bv +31612345678',
  atLeastOneRole: 'Minimaal moet er 1 vestiging toegangsrol worden gekozen voor een medewerker.',
  oops: 'Oeps!',
  anUnexpectedErrorHasOccured: 'Een onverwachte fout is opgetreden.',
  error404: 'Error 404 - Deze pagina is helaas niet gevonden',
  groupAlreadyExists: 'Er bestaat al een groep met dezelfde naam.',
  countryIsRequired: 'Land is benodigd',
  addressIsRequired: 'Adres regel 1 is benodigd',
  postalCodeIsRequired: 'Postcode is benodigd',
  cityIsRequired: 'Plaats is benodigd',
  slugIsAlreadyInUse: 'Web adres is al in gebruik',
  slugIsRequired: 'Web adres is benodigd',
  isRequired: 'is benodigd',
  contactEmployeeIsRequired: 'Contactpersoon is benodigd',
  vatNumberIsRequired: 'BTW nummer is benodigd',
  someQuestionsNotAnswered: 'Some required questions have not been answered.',
};

export default translations;
