import { OrganisationSettings } from '../translations-types';

const translations: OrganisationSettings = {
  employeeName: 'Employee name',
  activityDate: 'Activity date',
  activityType: 'Activity type',
  employeesActivity: 'Employees activity',
  activityData: 'Activity data',
  contactEmployee: 'Communication send to',
};

export default translations;
