import { Permissions } from '../translations-types';

const translations: Permissions = {
  organisationRoles: 'Organisatie rollen',
  salonsAccess: 'Vestiging toegang',
  rolesFor: 'Rollen voor',
  readCustomers: 'Inzien cliënten',
  manageCustomers: 'Beheer cliënten',
  manageEmployees: 'Beheer medewerkers',
  manageQuestionnaires: 'Beheer vragenlijsten',
  readEmployees: 'Inzien medewerkers',
  readQuestionnaires: 'Inzien vragenlijsten',
  managePermissions: 'Beheer rechten',
  manageSalons: 'Beheer vestiging(en)',
  manageSubscription: 'Beheer abonnementen',
  ownOrganisation: 'Eigen organisatie',
  readSalons: 'Inzien vestiging(en)',
  readSubscription: 'Inzien abonnement',
  manageTrainings: 'Beheer blauwdruk',
  description: {
    managePermissions:
      'Geeft medewerkers de mogelijkheid om organisatie rollen aan te passen en deze toe te kennen aan medewerkers.',
    manageSalons: 'Geeft medewerkers de mogelijkheid om alle vestigingen binnen de organisatie te beheren.',
    manageSubscription: 'Geeft medewerkers de mogelijkheid om het abonnement aan te passen en betalingen uit te voeren.',
    ownOrganisation: 'Geeft medewerkers de mogelijkheid om acties uit te voeren binnen de organisatie.',
    readSalons: 'Geeft medewerkers toegang tot alle vestigingen binnen de organisatie.',
    readSubscription: 'Geeft medewerkers de optie om de abonnement details en de betalingen in te zien.',
    manageTrainings: 'Geeft medewerker de mogelijkheid om Blauwdruk cursussen en modules te maken/wijzigen en verwijderen',
    manageDocuments: 'Geeft medewerker de mogelijkheid om informatiedocumenten en instemmingsformulieren te maken en wijzigen',
  },
  manageAdvice: 'Beheer Groei-modules',
  readAdvice: 'Inzien Groei-modules',
  manageDocuments: 'Beheer informatiedocumenten en instemmingsformulieren',
};

export default translations;
