import { AppSeason } from 'common/types/theme';
import getBaseTheme from './baseTheme';
import { pSBC } from './color-converter';
import { Theme } from './types';

const primaryColor = '#BD7770';
const getTheme = ({
  responsive,
  condensedMode,
  windowHeight,
  windowWidth,
}: {
  responsive: boolean;
  condensedMode: boolean;
  windowWidth: number;
  windowHeight: number;
}): Theme => {
  const baseTheme = getBaseTheme({ primaryColor, responsive, condensedMode, windowWidth, windowHeight });

  return {
    ...baseTheme,
    name: AppSeason.Fall,
    colors: {
      ...baseTheme.colors,
      primary: primaryColor,
      sideNavigation: '#C4A19D',
      sideNavigationTop: '#793E19',
      sideNavigationHover: '#B28581',
      coloredText: '##5e2e30',
      primaryTransparent: (transparency) => `rgba(189, 119, 112, ${transparency})`,
      primaryDarker: (darkness) => pSBC(-1 * darkness, primaryColor),
      primaryLighter: (lightness) => pSBC(lightness, primaryColor),
    },
  };
};

export default getTheme;
