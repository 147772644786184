import { Subscription } from '../translations-types';

const translations: Subscription = {
  currentSubscription: 'Current subscription',
  upgrade: 'Upgrade',
  succesfulUpdate: 'Subscription has been successfully updated.',
  validTo: 'Valid to',
  nextPayment: 'Next payment',
  nextRecurringPayment: 'Next recurring payment',
  limits: 'Current subscription limits',
  cancelSubscription: 'Cancel subscription',
  staySubscribed: 'Stay subscribed',
  cancelTitle: 'Are you sure you want to cancel your subscription?',
  cancelDescription: 'After your current plan expires you will not be able to use Re-sense any more.',
  noRecurringPayment: 'No scheduled recurring payment.',
  subscriptionOptions: 'Subscription options',
  yearly: 'Yearly',
  monthly: 'Monthly',
  customers: 'Customers',
  salons: 'Branch(-es)',
  employees: 'Employee(-s)',
  extras: 'Extras',
  cost: 'Cost',
  confirmingPayment: 'Confirming payment',
  paymentConfirmed: 'Payment has been confirmed!',
  paymentFailed: 'An error has occurred while processing payment',
  extendSubscription: 'Automatically extend my subscription',
  extendSubscriptionTooltip: 'Automatically charge me for the next period after my current subscription expires.',
  showExtras: 'Show extras',
  hideExtras: 'Hide extras',
  optionNotAvailable: 'Cannot upgrade from a yearly subscription to a monthly.',
  pricesExcludeVAT: 'Prices are excluding VAT',
  pleaseSelectAutoExtend: 'Please select if subscription should be automatically extended.',
  noOptionSelected: 'No option selected',
  subscriptionHasExpiredTitle: 'Subscription has expired',
  subscriptionHasExpiredDescriptionEmployee:
    'Unfortunately your active subscription has expired. To continue using Re-sense please contact your administrator.',
  subscriptionHasExpiredDescriptionAdmin:
    'Unfortunately your active subscription has expired. To continue using Re-sense please purchase a new subscription.',
};

export default translations;
