import { AdminRequestPasswordReset } from '../translations-types';

const translations: AdminRequestPasswordReset = {
  resetPassword: 'Reset password',
  success: 'Success!',
  linkHasBeenSent: 'Password reset link has been sent to',
  backToLogin: 'Back to login',
};

export default translations;
