import { ReportingDashboard } from '../translations-types';

const translations: ReportingDashboard = {
  commentType: 'Comment type: ',
  areYouSureYouWantToDelete: 'Are you sure you want to delete this comment?',
  deleteComment: 'Delete comment',
  edited: 'edited',
  lastEdited: 'Last edited at {{date}} ',
  addedComment: ' added comment at {{date}} ',
  startedFillingQuestionnaire: 'started filling questionnaire',
  sentQuestionnaire: 'sent questionnaire',
  to: 'to',
  answeredNQuestionsTo: 'answered {{count}} questions to',
  customerAnsweredNQuestionsTo: 'Customer answered {{count}} questions to',
  noActivityYet: 'No activity yet.',
  noCommentsYet: 'No comments yet.',
  question: 'Question',
  answer: 'Answer',
  answeredAt: 'Answered at',
  customerLoggedIn: 'Customer ({{email}}) successfully logged in.',
  customerFailedLogin: 'Customer ({{email}}) code verification did not succeed.',
  customerCodeRequest: 'Customer ({{email}}) requested for login code.',
  customerUpdatedOwnProfile: 'Customer updated their profile.',
  customerProfileUpdated: 'updated customer profile.',
  newDetailsOfCustomer: 'New details of customer',
  last7Days: 'Last 7 days',
  last30Days: 'Last 30 days',
  all: 'All',
  updated: 'updated',
  documentUpdated: 'Document',
  sentDocument: 'sent document',
  unsentDocument: 'unsent document',
};

export default translations;
