import { pSBC } from './color-converter';
import { Theme } from './types';

const getBaseTheme = ({
  responsive: resp,
  condensedMode,
  windowHeight,
  windowWidth,
  primaryColor,
}: {
  responsive: boolean;
  condensedMode: boolean;
  windowWidth: number;
  windowHeight: number;
  primaryColor: string;
}) => {
  const responsive = resp || condensedMode;

  return {
    colors: {
      positive: '#389e0d',
      negative: '#ff4d4f',
      warning: '#faad14',
      text: 'rgba(0, 0, 0, 0.85)',
      dashboardBlockBackground: `${pSBC(0.4, primaryColor)}C0`,
    } as Exclude<Theme['colors'], 'primary' | 'primaryLighter' | 'primaryDarker' | 'primaryTransparent'>,
    responsive,
    condensedMode,
    layout: {
      sideNavigationWidth: responsive ? 45 : 120,
      windowWidth,
      windowHeight,
    },
  };
};

export default getBaseTheme;
