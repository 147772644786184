import { CsvCustomersImportModal } from '../translations-types';

const translations: CsvCustomersImportModal = {
  successfully: 'Succesvol',
  createdN: 'Aangemaakt {{count}} cliënt(en)',
  updatedN: 'Gewijzigd {{count}} cliënt(en)',
  skippedNDuplicates: '{{count}} overgeslagen door duplicaten in bestand',
  skippedNEmptyMail: '{{count}} overgeslagen door een ontbrekend e-mailadres',
  nNewCustomers: '{{count}} nieuwe cliënt(en)',
  nExistingCustomers: '{{count}} bestaande cliënt(en)',
  emailColumnMustBeSelected: 'E-mail kolom moet worden geselecteerd',
  fileData: 'Bestandsinhoud',
  importDetails: 'Import details',
  importCustomers: 'Importeer cliënten van CSV bestand',
  import: 'Import',
  fileContainsHeader: 'Bestand bevat een kopregel',
  delimiter: 'Scheidingsteken',
  uploadFile: 'Upload bestand',
  importOptions: 'Import opties',
  column: 'Kolom',
  importNewUsers: 'Importeer nieuwe cliënt(en)',
  importExistingUsers: 'Importeer bestaande cliënt(en)',
};

export default translations;
