import { Questionnaire } from '../translations-types';

const translations: Questionnaire = {
  day: 'Dag',
  month: 'Maand',
  year: 'Jaar',
  multipleAnswerArePossible: 'Meerdere antwoorden zijn mogelijk.',
  enterYourOption: 'Kies je optie',
  other: 'Anders',
  question: 'Vraag',
  goToDashboard: 'Naar dashboard',
  resultsWillBeReviewed: 'Je ingestuurde antwoorden zijn toegevoegd in je mijn Re-sence omgeving.',
  thankYouForSubmitting: 'Bedankt voor het inzenden!',
  signOut: 'Uitloggen',
  customerTitle: 'Cliënt titel',
  employeeTitle: 'Medewerker titel',
  icon: 'Icoon',
  errorOpeningUrl: 'Er is fout opgetreden bij het openen van pagina',
};

export default translations;
