import i18n, { ResourceLanguage } from 'i18next';

export const extractCurrentFolderTranslations = (files: Record<string, unknown>) => {
  const values = Object.entries(files).reduce((acc, [fileName, translations]) => {
    const fileNameToUse = fileName.match('./(.*).ts')![1]!;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    acc[fileNameToUse] = (translations as any).default;
    return acc;
  }, {} as ResourceLanguage);

  return values;
};

export const translate = (key1: string, key2?: string, key3?: string, key4?: string) => {
  let formattedKey = `${key1}`;
  if (key2) {
    formattedKey += `.${key2}`;
  }

  if (key3) {
    formattedKey += `.${key3}`;
  }

  if (key4) {
    formattedKey += `.${key4}`;
  }

  return i18n.t(formattedKey);
};

export const translateWithOptions = (
  p1: any, // eslint-disable-line @typescript-eslint/no-explicit-any
  p2?: any, // eslint-disable-line @typescript-eslint/no-explicit-any
  p3?: any, // eslint-disable-line @typescript-eslint/no-explicit-any
  p4?: any, // eslint-disable-line @typescript-eslint/no-explicit-any
) => {
  if (!p4 && !p3) {
    return i18n.t(p1, p2);
  }

  if (!p4) {
    return i18n.t(`${p1}.${p2}`, p3);
  }

  return i18n.t(`${p1}.${p2}.${p3}`, p4);
};

export const translateUnsafe = (key: string, options?: { [key: string]: string }) => i18n.t(key, options);
