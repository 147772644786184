import { Courses } from '../translations-types';

const translations: Courses = {
  courses: 'Courses',
  addCourse: '+ Add course',
  addSegment: '+ Add segment',
  addModule: '+ Add module',
  editModule: 'Edit module',
  createModule: 'Create module',
  moduleDeleteConfirmation: 'Are you sure? This will delete the Module and all its Segments.',
  courseDeleteConfirmation: 'Are you sure? This will delete the Course and all its Modules.',
  deleteModule: 'Delete module',
  enterText: 'Enter text...',
  modules: 'Modules',
  createCourse: 'Create course',
  editCourse: 'Edit course',
  deleteCourse: 'Delete course',
  noModules: 'No modules yet',
  noCourses: 'No courses yet',
  noSegments: 'No segments yet',
  cost: 'Cost',
  discountCodes: 'Discount codes',
  enterDescription: 'Enter description',
  segmentX: 'Segment {{number}}',
};

export default translations;
