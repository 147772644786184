import { Advice } from '../translations-types';

const translations: Advice = {
  addAdvice: 'Toevoegen Groei-module',
  addAdviceCondition: 'Toevoegen Groei-module conditie',
  addAdviceGroup: 'Toevoegen Groei-module groep',
  adviceName: 'Groei-module naam',
  successfullyUpdated: 'Groei-module update succesvol',
  successfullyCreated: 'Groei-module succesvol aangemaakt',
  createAdvice: 'Aanmaken Groei-module',
  viewAdvice: 'Bekijken groei-module',
  editAdvice: 'Aanpassen groei-module',
  enterNewGroupName: 'Invoeren groepsnaam',
  group: 'Groep',
  adviceConditionName: 'Groei-module conditie naam',
  adviceGroupName: 'Groei-module groepsnaam',
  questions: 'Vragen',
  areYouSureYouWantToDeleteAdviceGroup: 'Wilt u deze Groei-module groep permanent verwijderen?',
  areYouSureYouWantToDeleteAdviceCondition: 'Wilt u deze Groei-module conditie permanent verwijderen?',
  conditions: 'Condities',
  advice: 'Groei-module',
  addOption: 'Toevoegen optie',
  podcastUrl: 'Podcast Url',
  videoUrl: 'Video Url',
  adviceOrUrl: 'Een Groei-module kan tekst en geluidsfragment zijn en/of een Url naar Video of Podcast.',
  watchOnVimeo: 'Bekijk op Vimeo',
  listedOnSpotify: 'Luister op Spotify',
  conditionsOrLogic: 'De Groei-module wordt weergegeven als aan een van deze condities wordt voldaan.',
  groupIsUsedTimes: 'Deze groep is  {{count}} keer gebruikt.',
  conditionIsUsedTimes: 'Deze conditie is {{count}} keer gebruikt.',
  noAdvice: 'Geen Groei-module om weer te geven',
  conditionModalInfoText: 'Conditie is waar als aan alle subcondities wordt voldaan.',
  adviceX: 'Groei-module {{number}}',
  audio: 'Geluidsfragment',
  changeAudio: 'Aanpassen geluidsfragment',
  removeAudio: 'Verwijderen geluidsfragment',
  chooseAudio: 'Kies geluidsfragment',
};

export default translations;
