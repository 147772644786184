import { MyProfileDrawer } from '../translations-types';

const translations: MyProfileDrawer = {
  editMyProfile: 'Wijzig mijn profiel',
  changePassword: 'Wachtwoord wijzigen',
  currentPassword: 'Huidige wachtwoord',
  confirmPassword: 'Bevestig wachtwoord',
  successfullyUpdatedProfile: 'Je profiel is gewijzigd.',
};

export default translations;
