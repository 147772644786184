import { AudioModal } from '../translations-types';

const translations: AudioModal = {
  errorOccuredConverting: 'Er is een fout opgetreden tijdens het converteren van het audio bestand',
  attachRecording: 'Toevoegen audio-bestand',
  recording: 'Opnemen...',
  createRecording: 'Opnemen geluidsfragment',
  stopRecording: 'Stop opname',
};

export default translations;
