import { Documents } from '../translations-types';

const translations: Documents = {
  consents: 'Consents',
  addDocument: 'Add document',
  formName: 'Form name',
  editDocument: 'Edit document',
  createDocument: 'Create document',
  notEditableAfterCreate: 'Note: Consent forms cannot be modified or deleted after they are created.',
  enterText: 'Enter text',
  noDocumentsToDisplay: 'No information documents to display.',
  noConsentRequests: 'No consent requests to display.',
  sendToCustomer: 'Send document to customer',
  sentAt: 'Sent at',
  sentBy: 'Sent by',
  status: 'Status',
  documentSent: '{{name}} sent to {{target}}.',
  pendingConsents: 'Pending consents',
  acceptedConsents: 'Accepted consents',
  declinedConsents: 'Declined consents',
  noDocumentsAvailable: 'No documents available',
  document: 'Information document',
  consentForm: 'Consent form',
  documents: 'Documents',
  duplicateDocument: 'Duplicate document',
  areYouSureYouWantToSendAgain: 'Customer already has this document. Are you sure you want to send it again?',
  areYouSure: 'Are you sure?',
  userWillNotBeAbleToSee: 'User will not be able to see the document.',
  documentToSend: 'Document to send',
  documentNotSelected: 'Document not selected',
};

export default translations;
