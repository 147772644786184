import { EmployeeDrawer } from '../translations-types';

const translations: EmployeeDrawer = {
  editEmployeeData: 'Edit employee data',
  createAnEmployee: 'Create an employee',
  limitReached:
    'Unfortunately a limit of {{employees}} employees has been reached. Please update your subscription to create more employees.',
};

export default translations;
