import { EditCustomerDrawer } from '../translations-types';

const translations: EditCustomerDrawer = {
  hasBeenUpdated: 'Cliënt is succesvol gewijzigd.',
  hasBeenHardDeleted: 'De cliënt is succesvol verwijderd.',
  editCustomer: 'Wijzigen cliënt',
  viewCustomer: 'Bekijk cliënt',
  customerAlreadyExists: 'Cliënt met opgeven e-mailadres bestaat al.',
  deleteForeverTitle: 'Wilt u cliënt "{{customerName}}" permanent verwijderen?',
  deleteForever: 'Permanent verwijderen',
  actionIrreversible: 'Deze actie kan niet ongedaan worden gemaakt.',
  customerDataWillBeRemoved: 'De cliënt en alle gerelateerde data zal permanent worden verwijderd uit het systeem.',
};

export default translations;
