import { CustomerLogin } from '../translations-types';

const translations: CustomerLogin = {
  openGmail: 'Open Gmail',
  openOutlook: 'Open Outlook',
  signIn: 'Inloggen op: Mijn Re-sence',
  signInTip: 'Gebruik het e-mailadres waarop je Re-sence e-mails ontvangt. Wij wensen je een prettige dag!',
  signInGoogle: 'Aanmelden met Google',
  signInApple: 'Aanmelden met ICloud',
  signInEmail: 'Aanmelden met e-mail',
  weWillEmail: 'We sturen je direct een email met een unieke inlogcode waarmee je zonder wachtwoord kunt aanmelden.',
  privacyAndTerms: 'Privacybeleid & Gebruikersovereenkomst',
  contactUs: 'Contact',
  or: 'OF',
};

export default translations;
