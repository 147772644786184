import { SupportedLanguage } from 'common/api/generated-api';
import { AppSeason } from 'common/types/theme';
import { EmployeeJwtClaims } from 'common/types/authentication';

enum LocalStorageKey {
  Language = 'language',
  CondensedMode = 'condensedMode',
  LoggedInEmployeeUser = 'loggedInEmployeeUser',
  'AppSeason' = 'appSeason',
  Slug = 'slug',
}

export class CommonLocalStorageService {
  protected parseJson(data: string | null) {
    try {
      if (data) {
        return JSON.parse(data);
      }
    } catch (error) {
      console.error(error); // eslint-disable-line no-console
    }

    return undefined;
  }

  setUserLanguage(language: SupportedLanguage) {
    localStorage.setItem(LocalStorageKey.Language, language);
  }

  getUserLanguage(): SupportedLanguage {
    const language = localStorage.getItem(LocalStorageKey.Language);
    const result = language as SupportedLanguage | undefined;

    return result || SupportedLanguage.Nl;
  }

  setAppSeason(season: AppSeason) {
    localStorage.setItem(LocalStorageKey.AppSeason, season);
  }

  getAppSeason(): AppSeason | null {
    const season = localStorage.getItem(LocalStorageKey.AppSeason) as AppSeason | null;
    return season;
  }

  setCondensedMode(value: boolean) {
    localStorage.setItem(LocalStorageKey.CondensedMode, value ? 'true' : 'false');
  }

  getCondensedMode(): boolean {
    const value = localStorage.getItem(LocalStorageKey.CondensedMode) === 'true';
    return value;
  }

  setLoggedInAdminUser(user: EmployeeJwtClaims) {
    localStorage.setItem(LocalStorageKey.LoggedInEmployeeUser, JSON.stringify(user));
  }

  removeLoggedInAdminUser() {
    localStorage.removeItem(LocalStorageKey.LoggedInEmployeeUser);
  }

  getLoggedInAdminUser(): EmployeeJwtClaims {
    const loggedInUser = localStorage.getItem(LocalStorageKey.LoggedInEmployeeUser);
    return loggedInUser ? this.parseJson(loggedInUser) : undefined;
  }

  setSlug(slug: string) {
    localStorage.setItem(LocalStorageKey.Slug, slug);
  }

  getSlug(): string | undefined {
    const slug = localStorage.getItem(LocalStorageKey.Slug);
    return slug || undefined;
  }
}

export default new CommonLocalStorageService();
