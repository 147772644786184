import { CreateCustomerDrawer } from '../translations-types';

const translations: CreateCustomerDrawer = {
  customer: 'Customer',
  hasBeenCreated: 'has been successfully created.',
  createNewCustomer: 'Create a new customer',
  customerAlreadyExistsExplanation:
    'Customer already exists (they might be in another branch). Saving will update customer assigned branch.',
  limitReached:
    'Unfortunately a limit of {{customers}} customers has been reached. Please update your subscription to create more customers.',
};

export default translations;
