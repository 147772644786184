import { AdminChangePassword } from '../translations-types';

const translations: AdminChangePassword = {
  passwordHasBeenChanged: 'Het wachtwoord is succesvol aangepast.',
  passwordHasBeenSet: 'Password has been successfully set. NL',
  changePassword: 'Wachtwoord aanpassen',
  setNewPassword: 'Set new password NL',
  confirmPassword: 'Bevestig wachtwoord',
  user: 'Gebruiker: {{email}}',
};

export default translations;
