import { Modal, notification } from 'antd';
import { HookAPI } from 'antd/es/modal/useModal';
import { NotificationInstance } from 'antd/es/notification/interface';
import React, { useMemo } from 'react';

export type AntdContextType = {
  modal: HookAPI;
  notification: NotificationInstance;
};

export const AntdContext = React.createContext<AntdContextType>({} as AntdContextType);

const AntdContextWrapper = ({ children }: { children: React.ReactNode }) => {
  const [modal, modalContextHolder] = Modal.useModal();
  const [notif, notificationContextHolder] = notification.useNotification();

  const value = useMemo(() => ({ modal, notification: notif }), [modal, notif]);

  return (
    <AntdContext.Provider value={value}>
      {modalContextHolder}
      {notificationContextHolder}
      {children}
    </AntdContext.Provider>
  );
};

export default AntdContextWrapper;
