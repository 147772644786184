import { EditCustomerDrawer } from '../translations-types';

const translations: EditCustomerDrawer = {
  hasBeenUpdated: 'Customer has been successfully updated.',
  hasBeenHardDeleted: 'Customer has been successfully deleted from the system.',
  editCustomer: 'Edit customer',
  viewCustomer: 'View customer',
  customerAlreadyExists: 'Customer with given email address already exists.',
  deleteForever: 'Delete forever',
  deleteForeverTitle: 'Delete customer "{{customerName}}" forever?',
  actionIrreversible: 'This action is not reversible.',
  customerDataWillBeRemoved: 'The customer and all their related data will be removed from the system.',
};

export default translations;
