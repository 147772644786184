import { CustomerLogin } from '../translations-types';

const translations: CustomerLogin = {
  openGmail: 'Open Gmail',
  openOutlook: 'Open Outlook',
  signIn: 'Sign in',
  signInTip: 'Please use the same emailaddress  as where you receive Re-sence emails on. Have a pleasant day!',
  signInGoogle: 'Sign in with Google',
  signInApple: 'Sign in with Google',
  signInEmail: 'Sign in with email',
  weWillEmail: 'We will email you a code for a password-free sign in.',
  privacyAndTerms: 'Privacy & Terms',
  contactUs: 'Contact Us',
  or: 'OR',
};

export default translations;
