import { Theme } from './types';

const updateColors = async (theme: Theme) => {
  const primaryColor = theme.colors.primary;

  document.getElementsByName('msapplication-TileColor')[0]?.setAttribute('content', primaryColor);
  document.getElementsByName('theme-color')[0]?.setAttribute('content', primaryColor);
  document.getElementById('iconSafariSVG')?.setAttribute('color', primaryColor);
};

const setupTheme = async (theme: Theme) => {
  await updateColors(theme);
};

export default setupTheme;
