import { Salons } from '../translations-types';

const translations: Salons = {
  salonName: 'Vestiging naam',
  customersCount: 'Aantal cliënten',
  employeesCount: 'Aantal medewerkers',
  addSalon: 'Toevoegen vestiging',
};

export default translations;
