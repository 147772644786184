import { AdminHeader } from '../translations-types';

const translations: AdminHeader = {
  addCustomer: 'Cliënt toevoegen',
  myProfile: 'Mijn profiel',
  signOut: 'Uitloggen',
  theme: 'Thema',
  trainings: 'Blauwdruk',
  condensedMode: 'Beknopte dashboard mode aan',
  condensedModeOff: 'Beknopte dashboard mode uit',
};

export default translations;
