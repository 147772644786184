import { Documents } from '../translations-types';

const translations: Documents = {
  consents: 'Instemmings-formulieren',
  addDocument: 'Toevoegen document',
  formName: 'Formulier-naam',
  enterText: 'Invoeren tekst',
  sentAt: 'Verstuurd op',
  sentBy: 'Verstuurd door',
  status: 'Status',
  noDocumentsToDisplay: 'Geen documenten om weer te geven.',
  noConsentRequests: 'Geen instemmingsformulier om weer te geven.',
  documentSent: '{{name}} verstuur naar {{target}}.',
  pendingConsents: 'In behandeling instemmingsformulieren',
  acceptedConsents: 'Geaccepteerde instemmingsformulieren',
  declinedConsents: 'Geweigerde instemmingsformuleren',
  document: 'Informatiedocument',
  consentForm: 'Instemmingsformulier',
  editDocument: 'Wijzigen informatiedocument',
  createDocument: 'Aanmaken informatiedocument',
  notEditableAfterCreate:
    'LET OP: Instemmingsformulieren kunnen niet meer worden aangepast of verwijderd nadat ze zijn aangemaakt.',
  sendToCustomer: 'Verstuur document naar cliënt',
  noDocumentsAvailable: 'Geen documenten beschikbaar',
  documents: 'Documenten',
  duplicateDocument: 'Dupliceren',
  areYouSureYouWantToSendAgain: 'Cliënt heeft dit document al eerder ontvangen. Wil je het nogmaals verzenden?',
  areYouSure: 'Weet je het zeker?',
  userWillNotBeAbleToSee: 'Cliënt zal het document niet kunnen inzien',
  documentToSend: 'Document om te versturen',
  documentNotSelected: 'Document niet geselecteerd',
};

export default translations;
