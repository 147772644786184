import { DiscountCode } from '../translations-types';

const translations: DiscountCode = {
  discountCode: 'Discount code',
  appliedDiscount: 'Applied discount',
  discountApplied: 'Discount applied',
  invalidOrExpired: 'Invalid or expired code',
  haveADiscount: 'Have a discount code?',
};

export default translations;
