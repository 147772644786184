import { Customer } from '../translations-types';

const translations: Customer = {
  removeElement: 'Verwijder element?',
  editCustomer: 'Aanpassen cliënt',
  fill: 'Invullen',
  fillNow: 'Vul de vragenlijst nu in',
  questionnaireSent: 'Vragenlijst succesvol verstuurd naar',
  send: 'Versturen',
  sendToCustomer: 'Verstuur vragenlijst naar cliënt',
  messageType: 'Bericht type',
  questionnaire: 'Vragenlijst',
  questionnaireToSend: 'Vragenlijst om te verzenden',
  submissionActivity: 'Inzending activiteit',
  addCustomer: 'Toevoegen cliënt',
  loadingCustomer: 'Inlezen cliënt...',
  customerNotFound: 'Cliënt niet gevonden',
  customElement: 'Aangepast element',
  enterSearchCriteria: 'Geef zoekcriteria op',
  defaultMessagingType: 'Standaard bericht type',
  questionnaireNotSelected: 'Vragenlijst niet geselecteerd',
  notEnoughCredits: 'Niet voldoende Whatsapp krediet.',
  customerDoesNotHaveAPhoneNumber: 'De cliënt heeft geen telefoonnummer.',
  sendTo: 'Versturen naar',
  purchaseMoreCredits: 'Toevoegen meer krediet',
  customerDefault: 'Cliënt instelling',
  sendQuestionnaires: 'Verstuur vragenlijsten',
  sendMultipleQuestionnaires: 'Verstuur meerdere vragenlijsten',
  failedToSendQuestionnaireTo: 'Fout bij verzenden vragenlijst naar: {{names}}.',
  sentQuestionnaireToCount: 'Versturen vragenlijst naar {{count}} cliënten.',
  selectCustomersCount: 'Geselecteerde cliënten ({{count}})',
  allowedMessagingTypes: 'Toegestane kanalen voor berichten',
  updateNotificationPreferencesDescription:
    'Via deze weg kun je aangeven hoe je jouw berichten wilt ontvangen of als je helemaal geen berichten wil ontvangen kun alles uitzetten. LET OP: Indien je GEEN berichten wens te ontvangen zullen wij ook niet meer in staat zijn om de Re-sence experience te bieden en je nieuwe vragenlijsten sturen. Uiteraard respecteren wij jouw besluit. Je kunt jou keuze later zelf ook weer aanpassen indien je wel weer wenst om de Re-sence experience te krijgen.',
  updateNotificationPreferencesTitle: 'Aanpassen berichten voorkeuren',
  customerDoesNotAcceptThisNotificationType: 'De cliënt heeft aangegeven geen berichten te willen ontvangen via dit kanaal',
};

export default translations;
